import React from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

const BookingCard = ({ data, index }) => {
  const { eventData, showData, ticketData } = data;
  const navigate = useNavigate();
  return (
    <div
      className="my-4 flex md:w-[45%]   bg-white  flex-wrap cursor-pointer"
      onClick={() => navigate(`/booking-history/${ticketData.id}`)}
    >
      <div className="flex justify-between border rounded-md shadow-lg w-[100%]  ">
        <div className="flex">
          <img
            src={eventData?.bannerImage || ""}
            alt=""
            className="p-3 w-[125px] h-[125px] "
            width="100%"
            height="100%"
          />
          <div className="flex flex-col  justify-center">
            <div className="sm:text-[16px] text-[14px] font-[600]">
              {eventData?.name || `${(ticketData?.amount * 0.01).toFixed(2)}$`}
            </div>
            <div
              className={`text-[11px] sm:text-[14px] ${
                ticketData.status == "success"
                  ? "text-green-500"
                  : ticketData.status == "pending"
                  ? "text-orange-500"
                  : "text-red-500"
              } font-[600]`}
            >
              {ticketData?.isUsed && ticketData.status == "success"
                ? "Scanned"
                : ticketData.status}
            </div>
            <div className="text-[10px] sm:text-[12px] text-gray-500">
              {!!showData?.startDate && (
                <p>
                  <p>
                    <Moment format="ll">{showData.startDate}</Moment>
                  </p>
                  <b>
                    <Moment format="h:mm A">{showData.startDate}</Moment>
                  </b>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingCard;
