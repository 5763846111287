import React from "react";

const BtnLoader = ({ stroke = "white", size = 14 }) => {
  // Calculate the width and height based on the original 13:14 aspect ratio
  const originalWidth = 13;
  const originalHeight = 14;
  const aspectRatio = originalWidth / originalHeight;

  const height = size; // The size prop defines the height
  const width = size * aspectRatio; // Calculate the width while maintaining the aspect ratio

  return (
    <svg
      width={width} // Set the calculated width
      height={height} // Set the height based on the size prop
      viewBox={`0 0 ${originalWidth} ${originalHeight}`} // Keep the original viewBox
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="spinner"
    >
      <path
        d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
        stroke={stroke}
      />
    </svg>
  );
};

export default BtnLoader;
