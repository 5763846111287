import React, { useEffect, useState } from "react";
import verifyImg from "./images/verify.png";
import { CiCircleAlert } from "react-icons/ci";
import OrderId from "./orderIdDiv";
import { useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import Loader from "../../components/shared/loader";
import { calculateDaysDifference, getAddressString } from "../../utils/helpers";
import Moment from "react-moment";
import QRCode from "react-qr-code";
import AlertPopup from "../../components/popups/alertPopup";
import BtnLoader from "../../components/shared/btnLoader";

const BookingConfirm = () => {
  const [data, setData] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [refetch, setRefetch] = useState(0);

  const { id } = useParams();

  const getTicketData = async () => {
    try {
      const res = await httpsCallable(functions, "getTicketInfo")({ id });
      if (res.data?.status == 200) setData(res.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getTicketData();
  }, [id, refetch]);

  const { eventData, showData, ticketData } = data;
  const status = ticketData?.status;

  const showCancelButton = showData?.startDate
    ? calculateDaysDifference(showData.startDate) > 7
    : false;

  const cancelTicketHanlder = async () => {
    setShowLoader(true);
    try {
      const res = await httpsCallable(functions, "cancelTicketHanlder")({ id });
      if (res.data?.status == 200) setRefetch((i) => i + 1);
    } catch (err) {
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  return ticketData ? (
    <div className="w-[100%] md:w-[80%]  mx-auto  mb-10">
      <div
        className={`${
          status == "success"
            ? "bg-green-400"
            : status == "pending"
            ? "bg-orange-400"
            : "bg-red-400"
        } w-full h-[20vh] sm:h-[25vh]`}
      >
        <div className="w-[90%] mx-auto flex items-center pt-[2rem] sm:pt-12 ">
          <span
            className="text-[30px] sm:text-[36px] tracking-wide ms-3 font-bold
           text-white drop-shadow-2xl"
          >
            {status == "success"
              ? "Booking Confirmed"
              : status == "pending"
              ? "Payment pending"
              : status == "refunded" || status == "refundInitiated"
              ? "Refunded"
              : "Booking failed"}
          </span>
          {status == "success" && (
            <img src={verifyImg} alt="" className="w-[35px] h-[35px]" />
          )}
        </div>
      </div>
      <div
        className="bg-gray-100 mx-auto  overflow-hidden  pt-2 shadow-md w-[90%] 
      rounded-xl  mt-[-2rem] sm:top-[18vh] min-h-[30vh]"
      >
        {/* absolute lgao */}
        <div className=" flex items-center px-3 sm:px-7  border-b  justify-between  ">
          <div className=" w-[50%]">
            <div className="text-[14px] sm:text-[25px] lg:text-[35px] cursor-pointer font-bold">
              {eventData?.name || `${(ticketData?.amount * 0.01).toFixed(2)}$`}
            </div>
            <div className="mt-2 gap-2 flex">
              <span className="px-3 py-1 border rounded-md  cursor-pointer">
                {eventData?.eventType}
              </span>
              <span className="px-3 py-1 border cursor-pointer rounded-md">
                {eventData?.genre}
              </span>
            </div>
            {!!eventData?.address && (
              <div className="mt-2 font-[600]">
                {getAddressString(eventData.address, true)}
              </div>
            )}
            {/* <div className="text-blue-500 cursor-pointer font-[600]">
              View Address
            </div> */}
          </div>
          {!!eventData?.bannerImage && (
            <img
              src={eventData.bannerImage}
              alt=""
              className="h-[23vh] sm:h-[35vh] w-[8rem] sm:w-[12rem] md:w-[15rem] rounded"
            />
          )}
        </div>
        {status == "success" && (
          <div className="pt-6 pb-2 px-3 sm:px-7 ">
            <p className="text-center  text-gray-500 uppercase mt-4 font-[600]">
              {ticketData?.isUsed ? "Ticket used" : "Scan Qr Code at Venue"}
            </p>
            <div className="flex py-4 items-center justify-between">
              <div>
                <div className="flex mb-3 text-[13px] lg:text-[20px] gap-[7rem]">
                  <div className="">
                    {!!showData?.startDate && (
                      <p>
                        <p>
                          <Moment format="ll">{showData.startDate}</Moment>
                        </p>
                        <b>
                          <Moment format="h:mm A">{showData.startDate}</Moment>
                        </b>
                      </p>
                    )}
                  </div>
                </div>
                <div className="text-[14px] lg:text-[20px]">
                  <p>
                    <p>Seats</p>
                    {ticketData.seatInfo.map((item) => (
                      <b>
                        {item.category} - {item?.detail || item.volume}
                        <br />
                      </b>
                    ))}
                  </p>
                </div>
              </div>
              <div className="cursor-pointer w-[6rem] sm:w-[10rem]">
                {!ticketData?.isUsed && (
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`${id}__&&__${ticketData.eventId}`}
                    viewBox={`0 0 256 256`}
                  />
                )}
              </div>
            </div>
            <OrderId ticketData={ticketData} />
          </div>
        )}

        <div className=" bg-red-100 p-4 gap-2">
          <div className="flex flex-row items-center justify-center">
            {status === "success" ? (
              showCancelButton ? (
                <button
                  onClick={() => setShowPopup(true)}
                  className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-6 rounded transition duration-150 ease-in-out"
                >
                  {showLoader ? <BtnLoader /> : "Cancel Ticket"}
                </button>
              ) : (
                <p>Booking Confirmed</p>
              )
            ) : (
              <div className="flex items-center text-sm md:text-base gap-2">
                <CiCircleAlert className="text-red-500" size={24} />
                <p>
                  {status === "pending"
                    ? "Awaiting payment confirmation."
                    : status === "refunded" || status === "refundInitiated"
                    ? `Refund of ${(ticketData.amountAfterFee * 0.01).toFixed(
                        2
                      )}$ issued. Funds are typically transferred within a span of 10 business days.`
                    : "Payment failed. If the amount was deducted, it will be refunded."}
                </p>
              </div>
            )}
          </div>
          {status == "pending" && (
            <div
              className="flex justify-center mt-1 hover:cursor-pointer"
              onClick={() => {
                setData({});
                getTicketData();
              }}
            >
              <p className="font-semibold">Refresh</p>
            </div>
          )}
        </div>
        {showPopup && (
          <AlertPopup
            title={`Refund amount will be ${(
              ticketData.amountAfterFee * 0.01
            ).toFixed(2)}$. Are you sure you want to cancel the ticket?`}
            buttons={[
              {
                text: "Yes",
                onPress: cancelTicketHanlder,
                className: "bg-red-500 text-white",
              },
              {
                text: "No",
                onPress: () => {},
                className: "bg-gray-500 text-white",
              },
            ]}
            hidePopup={() => setShowPopup(false)}
          />
        )}
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default BookingConfirm;
