import React from "react";
import Slider from "../../../components/shared/slider";

import premierLogo from "../images/premierImg/premiereplay.png";

const Recommended = ({ data }) => {
  return (
    <div className="bg-[#2B3148]   py-8 mt-12 md:mt-16 mb-16">
      <div className="w-[86%] mx-auto">
        <div className="flex">
          <div className="text-[white] w-[60px] sm:w-[80px]">
            <img
              className="w-full h-full"
              src={premierLogo}
              width={0}
              height={0}
              sizes="100vw"
              alt="Image not Found"
            />
          </div>
          <div className=" ">
            <div
              className="text-[25px] sm:text-[33px] mt-2 leading-7 sm:leading-10 
                         uppercase font-mono"
            >
              Recommended
            </div>
            <div className="text-[10px] sm:text-[14px] text-gray-300">
              Trending Events
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto w-[95%] bg-[#2B3148] mt-8">
        <Slider
          data={data}
          sliderTitle={""}
          titleClass="text-white"
          extraClass="pb-2 "
        />
      </div>
    </div>
  );
};

export default Recommended;
