import React, { useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import { useState } from "react";
import Select from "react-select";
import "./ticketType.css";
import { Toast } from "flowbite-react";
import { maxTicketAllowed } from "../../../constants";

const emptyTicket = {
  category: "",
  volume: 0,
};

const TicketTypePopUp = ({ onclose, selectedShow, onCheckout, showLoader }) => {
  const [form, setForm] = useState([{ ...emptyTicket }]);
  const [ticketSetup, setTicketSetup] = useState([]);
  const [tick, setTick] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTicketSetup(
      selectedShow.ticketSetup.map((item) => ({
        ...item,
        label: `${item.category} (${item.price}$)`,
        value: item.category,
      }))
    );
  }, [selectedShow]);

  const updateForm = (ind, key, val) => {
    if (key == "volume" && val < 0) return;
    var tempArr = [...form];
    tempArr[ind][key] = val;
    if (key == "category") {
      tempArr[ind]["price"] = ticketSetup.find(
        (item) => item.category == val
      ).price;
    }
    setForm(tempArr);
    setTick(tick + 1);
  };

  const onRemove = (ind) => {
    if (showLoader) return;
    var tempArr = [...form];
    tempArr.splice(ind, 1);
    setForm(tempArr);
    setTick(tick + 1);
  };

  const onAdd = () => {
    if (showLoader) return;
    var tempArr = [...form];
    tempArr.push({ ...emptyTicket });
    setForm(tempArr);
    setTick(tick + 1);
  };

  const availableCategories = ticketSetup.filter((item) => {
    var res = true;
    form.forEach((formItem) => {
      if (formItem.category == item.value) res = false;
    });
    return res;
  });

  useEffect(() => {
    var billTotal = 0;
    form.forEach((item) => {
      if (item.volume) billTotal += item.volume * item.price;
    });
    setTotal(billTotal);
  }, [tick]);

  return (
    <div className="duration-300 bg-userdata px-6 py-4  bg-[#F5F5F5] rounded-md w-[100%] flex justify-center fixed top-0  left-0 z-[9999999] items-center pt-[5rem] h-[100vh]">
      <div className="md:w-[40%] sm:w-[50%] lg:w-[32%] xl:w-[30%] w-[100%] flex text-start px-6 py-4 rounded-md bg-grey-400 relative bg-white max-h-[90%] overflow-y-auto custom-scrollbar">
        <div
          onClick={onclose}
          className="cursor-pointer pointer-curshor absolute right-0 top-0 flex justify-center text-center items-center z-20 text-red-900"
        >
          <IoIosCloseCircle size="1.6em" />
        </div>
        <div className="relative  w-full text-start ">
          <div className="font-bold text-center mb-2">Select Tickets</div>
          <div className="text-[13px]">
            *Select ticket type and enter quantity
          </div>
          <div className="text-[13px]">
            *To book different types of tickets, click on Add More.
          </div>
          <div className="text-[13px]">
            *You can book a maximum of {maxTicketAllowed} tickets.
          </div>
          <div className="mt-2">
            {ticketSetup.map((item) =>
              item?.description ? (
                <div className="text-[13px]">
                  <b>
                    {item.category} ({item.available} available)
                  </b>
                  - {item.description}
                </div>
              ) : null
            )}
          </div>
          {form.map((item, ind) => {
            const options = ticketSetup
              .filter((cat) => cat.value == item.category)
              .concat(availableCategories);
            const selectedOption = options.find(
              (option) => option.value === item.category
            );
            return (
              <div className="relative p-4 border-dashed border border-gray-300 rounded-lg mt-4">
                <Select
                  placeholder="Select ticket type"
                  value={selectedOption}
                  onChange={(val) => updateForm(ind, "category", val?.value)}
                  options={options}
                />
                <div className="flex justify-between w-full  mt-2">
                  <div className=" text-center h-fit w-full relative ">
                    <input
                      type="number"
                      className="z-0 w-full rounded py-2 px-4 placeholder-gray-800 outline-none border border-gray-300 rounded-md"
                      placeholder="Quantity"
                      value={item.volume}
                      onChange={(e) =>
                        updateForm(ind, "volume", e.target.value)
                      }
                    />
                  </div>
                </div>
                {form.length > 1 && (
                  <button
                    className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-red-500 rounded-full p-1 m-1 hover:bg-red-100"
                    onClick={() => onRemove(ind)}
                  >
                    <IoIosCloseCircle size="1.2em" />
                  </button>
                )}
              </div>
            );
          })}
          {ticketSetup.length > form.length && (
            <div
              className="text-black px-1 py-1 text-center cursor-pointer mt-4"
              onClick={onAdd}
            >
              Add more
            </div>
          )}
          {total > 0 && (
            <div className="flex w-full justify-center align-center mt-4">
              <div
                className="bg-[#333545] rounded-md text-white w-[80%] px-1 py-2 text-center cursor-pointer text-center"
                onClick={() => onCheckout(form, total)}
              >
                {`Proceed to pay (${total}$)`}
              </div>
            </div>
          )}
          <div className="h-5" />
        </div>
      </div>
    </div>
  );
};

export default TicketTypePopUp;
