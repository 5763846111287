import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { httpsCallable } from "firebase/functions";

import { functions, firestore } from "../../../firebase";
import "./eventBanner.css";
import { useUserContext } from "../../../context/User";
import { doc, getDoc } from "firebase/firestore";

const EventBanner = ({ data, eventId }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [totalLikes, setTotalLikes] = useState(data?.likes || 0);
  const [checkingLikeStatus, setCheckingLikeStatus] = useState(true);

  const { user, loggedIn } = useUserContext();
  const { address } = data;

  const sliderImages = data?.otherImages?.length
    ? data.otherImages.map((item) => ({ url: item }))
    : [];

  const updateLikeStatus = async () => {
    setIsLiked(!isLiked);
    setTotalLikes(isLiked ? totalLikes - 1 : totalLikes + 1);
    try {
      await httpsCallable(
        functions,
        "updateEventLikeStatus"
      )({
        status: !isLiked,
        userId: user.userId,
        userName: user?.name || "",
        userEmail: user.email,
        businessId: data.userId,
        eventId,
      });
    } catch (err) {
      console.log("err", err);
    }
  };

  const fetchkLikeStatus = async () => {
    if (!data?.userId || !user?.userId || !eventId) return;
    try {
      const fansDocRef = doc(
        firestore,
        "Fans",
        `${data.userId}_${user.userId}`
      );
      const fansDocSnap = await getDoc(fansDocRef);

      if (fansDocSnap.exists()) {
        const fansDocData = fansDocSnap.data();
        console.log(fansDocData);
        const { likedEvents } = fansDocData;
        if (likedEvents?.length && likedEvents.includes(eventId))
          setIsLiked(true);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setCheckingLikeStatus(false);
    }
  };

  useEffect(() => {
    fetchkLikeStatus();
  }, [data, user]);

  return (
    <>
      <div className="w-[100%] sm:h-[60vh] lg:h-[70vh] xs:h-[40vh] relative">
        <img
          src={data.bannerImage}
          alt=""
          className="w-[100%] absolute z-0 left-0 top-0 h-[100%]"
          style={{ paddingLeft: "25%" }}
        />
        <div className="w-[100%] h-[100%] absolute z-10 bgBannerOverlay ">
          <div className="w-[100%] sm:w-[85%] px-2 h-[100%] py-6 flex  gap-4 lg:gap-8 mx-auto">
            {!!sliderImages.length && (
              <div className="w-[150px] sm:w-[220px] md:w-[230px] lg:w-[290px] h-[100%] flex justify-center">
                <Swiper
                  spaceBetween={0}
                  slidesPerView={1}
                  modules={[Autoplay]}
                  autoplay={{ delay: 1500 }}
                >
                  {sliderImages.map((item) => (
                    <SwiperSlide>
                      <img
                        src={item.url}
                        className={`w-[100%] h-[100%] rounded-lg`}
                        alt=""
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
            <div className="text-white w-[60%] mt-2 lg:mt-[-2rem] lg:py-[9rem] xl:py-[10rem] sm:py-[7rem] xs:py-[3rem]">
              <h1 className="xs:text-[1.2rem] sm:text-[1.3rem] md:text-[1.3rem]  lg:py-2 lg:text-[2.2rem]  lg:leading-relaxed  font-bold overflow-hidden">
                {data?.name}
              </h1>
              <p className="font-[400] leading-6 lg:leading-9 flex gap-1 md:text-xl xs:text-[14px]">
                {`${address.name}, ${address.detail}${
                  address.extraDetail ? `, ${address.extraDetail}` : ""
                }, ${address.city}, ${address.state} (${address.zip})`}
              </p>
              {!!data?.eventType && (
                <p className="font-[500] leading-6 lg:leading-9 flex gap-1 md:text-xl xs:text-[14px]">
                  {data.eventType}
                </p>
              )}
              {!!data?.genre && (
                <p className="font-[500] leading-6 lg:leading-9 flex gap-1 md:text-xl xs:text-[14px] text-red-400">
                  {data.genre}
                </p>
              )}
              {loggedIn && !checkingLikeStatus && (
                <div className="flex items-center mt-3">
                  {isLiked ? (
                    <FaHeart
                      size="2rem"
                      color="#a8325c"
                      className="hover:cursor-pointer"
                      onClick={updateLikeStatus}
                    />
                  ) : (
                    <FaRegHeart
                      size="2rem"
                      color="#a8325c"
                      className="hover:cursor-pointer"
                      onClick={updateLikeStatus}
                    />
                  )}
                  {!!totalLikes && (
                    <p className="font-medium ml-2 text-[16px]">
                      {totalLikes || 0} {totalLikes > 1 ? "likes" : "like"}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventBanner;
