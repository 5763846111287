import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { FaFileDownload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "react-moment";

import { firestore } from "../../firebase";
import { adminAccounts } from "../../constants";
import { useUserContext } from "../../context/User";
import { auth } from "../../firebase";
import BtnLoader from "../../components/shared/btnLoader";

function SeatingRequest() {
  const [requestData, setRequestData] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  const { id } = useParams();
  const { user } = useUserContext();
  const isAdmin = adminAccounts.includes(user?.userId);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const docRef = doc(firestore, "TicketSetup", id);
      var snap = await getDoc(docRef);
      if (snap.exists) setRequestData({ ...snap.data() });
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (isAdmin) getData();
  }, []);

  const onProceed = async () => {
    if (showLoader) return;
    try {
      setShowLoader(true);
      const token = await auth.currentUser.getIdToken(true);
      setShowLoader(false);
      navigate(`/create-seat-map/${token}/${id}`);
    } catch (err) {
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-[60vh] bg-gray-100">
      {isAdmin ? (
        <div className="w-1/2 flex flex-col justify-center items-center bg-white shadow-lg p-6 m-4 text-center rounded-lg font-semibold">
          <h2 className="text-xl mb-4 text-[#031134]">{requestData.name}</h2>
          <p className="mb-4">
            Date:{" "}
            <Moment format="DD-MM-YYYY" className="text-gray-600">
              {requestData.createdAt}
            </Moment>
          </p>
          <p className="mb-4 text-gray-700">Files</p>
          {requestData.files &&
            requestData.files.map((file, index) => (
              <div className="flex flex-row items-center mb-2" key={index}>
                <h3 className="mr-2 text-gray-800">{file.name}</h3>
                <a
                  href={file.uri}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#031134] hover:text-[#f56a00] transition duration-300"
                >
                  <FaFileDownload />
                </a>
              </div>
            ))}
          <button
            className="rounded-full py-2 px-4 text-sm capitalize bg-[#031134] hover:bg-[#f56a00] text-white mt-4 transition duration-300 ease-in-out"
            onClick={onProceed}
          >
            {showLoader ? (
              <BtnLoader stroke="white" size={20} />
            ) : (
              "Create Seat Map"
            )}
          </button>
        </div>
      ) : (
        <h2 className="text-xl mb-4 text-[#031134]">Admin access required</h2>
      )}
    </div>
  );
}

export default SeatingRequest;
