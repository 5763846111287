import React from "react";
import { BsChevronRight } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi";
import { LuShoppingBag } from "react-icons/lu";
import {
  MdOutlineLogin,
  MdOutlineLogout,
  MdOutlinePrivacyTip,
  MdAttachMoney,
} from "react-icons/md";
import SideSliderList from "../../shared/sideSliderList";
import { useUserContext } from "../../../context/User";
import { useNavigate } from "react-router-dom";

const SideSliderMain = ({ open, onClose, showLoginPopup }) => {
  const { loggedIn, user, logoutHandler } = useUserContext();
  const navigate = useNavigate();

  return (
    <>
      <div
        className={` border-black-100  shadow-2xl w-[300px] sm:w-[375px] z-[9999] h-[100vh] overflow-auto ${
          open
            ? "right-0 duration-700"
            : "right-[-110%] sm:right-[-100%] duration-700"
        } bg-white pb-2 fixed top-[77px] sm:top-[0]  flex-col`}
      >
        <div className="flex justify-between bg-black text-white py-2 px-2 ">
          <div>
            <div className="text-[22px] sm:text-[27px] font-semibold">
              {user?.name ? user.name : `Hey!`}
            </div>
            <div
              className="flex items-center text-[11px] sm:text-[14px] cursor-pointer "
              onClick={() => {
                if (loggedIn) {
                  navigate("profile");
                  onClose();
                } else showLoginPopup();
              }}
            >
              {loggedIn ? "Edit Profile" : "Sign In"}
              <BsChevronRight />
            </div>
          </div>
          {user?.profileImage ? (
            <div className="inline-block mt-2">
              <img
                className="w-40 h-40 rounded-full border-2 border-white object-cover"
                src={user.profileImage}
                style={{ width: "40px", height: "40px" }}
              />
            </div>
          ) : (
            <div className="text-[48px] sm:text-[60px] ">
              <BiUserCircle />
            </div>
          )}
        </div>
        {loggedIn && (
          <SideSliderList
            title="Booking History"
            para="View all your bookings & purchases"
            logo={
              <LuShoppingBag className="w-[10%] text-[14px] sm:text-[20px]" />
            }
            extraClasss="border-b-2"
            onPress={() => {
              onClose();
              navigate("booking-history");
            }}
          />
        )}

        <SideSliderList
          title="Privacy Policy"
          para="Check our privacy policy"
          logo={
            <MdOutlinePrivacyTip className="w-[10%] text-[14px] sm:text-[20px]" />
          }
          extraClasss="border-b-2"
          onPress={() => {
            onClose();
            navigate("/privacy-policy");
          }}
        />
        <SideSliderList
          title="Create Events"
          para="Become an Event Creator"
          logo={
            <MdAttachMoney className="w-[10%] text-[14px] sm:text-[20px]" />
          }
          extraClasss="border-b-2"
          onPress={() => {
            onClose();
            navigate("/create-events");
          }}
        />
        {loggedIn ? (
          <SideSliderList
            title="Logout"
            logo={
              <MdOutlineLogout className="w-[10%] text-[14px] sm:text-[20px]" />
            }
            extraClasss="border-b-2"
            onPress={() => logoutHandler(navigate, onClose)}
          />
        ) : (
          <SideSliderList
            title="Login/Register"
            para="Start Booking Tickets"
            logo={
              <MdOutlineLogin className="w-[10%] text-[14px] sm:text-[20px]" />
            }
            extraClasss="border-b-2"
            onPress={showLoginPopup}
          />
        )}
      </div>
    </>
  );
};

export default SideSliderMain;
