import React from "react";
import verifyImg from "../images/verify.png";
import { IoTicketSharp } from "react-icons/io5";

const OrderId = ({ ticketData }) => {
  return (
    <div className="rounded-md border w-[100%] bg-white overflow-hidden my-2">
      <div className="flex justify-between border-b px-4 text-[18px] py-3 bg-[#EEF5FF]">
        <div className="font-medium">Total Amount Paid</div>
        <div className="font-medium">${ticketData.amount / 100}</div>
      </div>

      {ticketData.seatInfo.map((item) => (
        <div className="flex justify-between border-b px-4 text-[18px] py-3">
          <div className="">
            <div className="flex gap-2 font-medium">
              Tickets
              <span>
                <img
                  src={verifyImg}
                  alt=""
                  className="w-[20px] h-[20px] mt-1"
                />
              </span>
            </div>
            <div className="flex gap-1">
              <IoTicketSharp className="mt-1" />
              {item.volume} x {item.category} @ ${item.price} each
            </div>
          </div>
          <div>
            <div className="font-medium">${item.volume * item.price}</div>
          </div>
        </div>
      ))}

      {/* <div className="flex justify-between border-b px-4 text-[18px] py-3">
        <div>
          <div className="font-medium">Taxes & Fees</div>
          <div>Booking Charges (incl.GST)</div>
        </div>
        <div>
          <div className="font-medium">₹80.24</div>
          <div>₹80.24</div>
        </div>
      </div> */}
    </div>
  );
};

export default OrderId;
