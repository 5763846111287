import React from "react";
import { useState } from "react";
import { AiOutlineCaretDown, AiOutlineSearch } from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import UniVersalContainer from "../container";
import LoginPopup from "../../popups/loginPopup";
import LocationPopup from "../../popups/locationPopup";
import SideSliderMain from "../../popups/sideSliderMain";
import "./index.css";
import { useUserContext } from "../../../context/User";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Navbar = () => {
  const [popupVisible, setPopupVisible] = useState(0);

  const { loggedIn } = useUserContext();
  const navigate = useNavigate();

  const { pathname, search } = useLocation();
  const isCreateSeatPage = pathname.includes("create-seat-map");

  const query = new URLSearchParams(search);
  const toHideNavFooter = query.get("hideNavFooter");

  return pathname == "/chatSupport" || toHideNavFooter ? null : (
    <>
      <div
        className="w-full sticky sm:z-[88] z-[999999] top-0 left-0 
      right-0 flex mb-0 justify-center bg-[#1b2842] py-5 sm:py-5"
      >
        <div
          className="w-full sm:w-[85%]  mx-3 sm:mx-auto    
        flex  gap-4 sm:gap-0 justify-between "
        >
          <div
            className="xl:w-[70%] md:w-[55%] w-[90%] 
          justify-between flex items-center text-center"
          >
            <div
              className="text-[25px] sm:text-3xl font-mono font-bold  
            pe-12 sm:px-4 text-white cursor-pointer"
              onClick={() => navigate("/")}
            >
              TICKE
              <span className="text-[#C4242B]">TI</span>NI
            </div>
            {!isCreateSeatPage && false && (
              <div
                className=" items-center xl:w-[73%] w-[50%] border-2
             rounded-md bg-white  gap-3  text-gray-500  flex "
              >
                <AiOutlineSearch
                  className="text-gray-500 ms-2 text-[14px] 
              sm:text-[25px] md:text-gray-500"
                />
                <input
                  type="text"
                  className="text-[14px] sm:text-[20px] w-[85%] outline-none border-none"
                  placeholder="Search for"
                />
              </div>
            )}
          </div>
          {!isCreateSeatPage && (
            <div
              className="xl:w-[25%] md:w-[40%] w-[9%] 
            flex justify-between  items-center  text-white "
            >
              {/* <div
              className="hidden md:flex"
              >
              <div className=" pe-1" onClick={() => openPopup("popup1")}>
                Select your City{" "}
              </div>
              <AiOutlineCaretDown className="mt-[6px] " />
            </div> */}

              {loggedIn || pathname == "/login" ? (
                <div />
              ) : (
                <div
                  className=" md:block hidden cursor-pointer 
               rounded-md bg-[#D80032] px-4 py-2 ml-24"
                  onClick={() => setPopupVisible(2)}
                >
                  Sign in
                </div>
              )}
              <FaBars
                className=" text-[28px] cursor-pointer  sm:text-[35px]"
                onClick={() => setPopupVisible(3)}
              />
            </div>
          )}
        </div>
      </div>
      {popupVisible == 2 && (
        <div
          className="w-[100%] h-[100vh] z-[999] fixed top-0 left-0 "
          onClick={() => setPopupVisible(0)}
        ></div>
      )}

      {/* <LocationPopup open={showPopups.popup1} />
      {!!showPopups.popup2 && (
        <div
          className="bg-userdata w-[100%] h-[100vh] z-[999] fixed top-0 left-0 "
          onClick={() => closePopup("popup1")}
        ></div>
      )} */}

      {popupVisible == 2 && (
        <LoginPopup
          onClose={() => setPopupVisible(0)}
          open={popupVisible == 2}
        />
      )}

      {popupVisible == 3 && (
        <div
          className="w-[100%] duration-200 h-[100vh] z-[999] fixed top-0 right-0 "
          onClick={() => setPopupVisible(0)}
        ></div>
      )}

      <SideSliderMain
        open={popupVisible == 3}
        onClose={() => setPopupVisible(0)}
        showLoginPopup={() => setPopupVisible(2)}
      />
    </>
  );
};

export default Navbar;
