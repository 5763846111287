import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./calender.css";
import { Navigation } from "swiper/modules";
import Moment from "react-moment";

const CalendarComponent = ({ shows, selectedDate, setSelectedDate }) => {
  return (
    <div className="w-[100%] flex justify-center ">
      <div className="w-[90%] sm:w-[60%] flex justify-center">
        <Swiper
          style={{
            "--swiper-navigation-color": "#000",
            "--swiper-navigation-bg": "#808080",
            "--swiper-navigation-size": "20px",
            "--swiper-button-prev": "displayNone",
            " --swiper-navigation-sides-offset": "-10px",
          }}
          breakpoints={{
            375: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          autoplay={{
            delay: 1000,
            disableOnInteraction: true,
          }}
          slidesPerView={5}
          spaceBetween={30}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper custom-swiper"
        >
          <div className="w-[100%] sm:w-[30%]  ">
            <ul className="w-[100%] flex justify-center">
              {Object.keys(shows).map((key, index) => (
                <SwiperSlide className="">
                  <div
                    key={key}
                    className={`${
                      key === selectedDate
                        ? "bg-red-500 text-white rounded-md px-2 border-none"
                        : ""
                    }`}
                    onClick={() => setSelectedDate(key)}
                  >
                    <div className="text-[13px] cursor-pointer">
                      <Moment format="dddd">{key}</Moment>
                    </div>
                    <div className="text-[20px] cursor-pointer">
                      <Moment format="D">{key}</Moment>
                    </div>
                    <div className="text-[13px] cursor-pointer">
                      <Moment format="MMMM">{key}</Moment>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </ul>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default CalendarComponent;
