import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../screens/home";
import SingleEvent from "../screens/singleEvent";
import Profile from "../screens/profile";
import Checkout from "../screens/checkout";
import BookHistory from "../screens/bookHistory";
import BookingConfirm from "../screens/bookingConfirm";
import CreateSeatMap from "../screens/createSeatMap";
import TicketOptions from "../screens/ticketOptions";
import PrivateRoute from "./privateRoute";
import LoginPage from "../screens/login";
import ChatSupport from "../screens/chatSupport";
import SeatingRequestList from "../screens/seatingRequestList";
import SeatingRequest from "../screens/seatingRequest";
import Events from "../screens/events";
import PrivacyPolicy from "../screens/static/privacyPolicy";
import TermsOfUse from "../screens/static/termsOfUse";
import TermsConditions from "../screens/static/termsConditions";
import PurchaseTerms from "../screens/static/purchaseTerms";
import CreateEvents from "../screens/createEvents";

const Root = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="events/:tag?" element={<Events />} />
      <Route path="/single-event/:id" element={<SingleEvent />} />
      <Route
        path="/single-event/:eventId/:showId"
        element={<TicketOptions />}
      />
      <Route element={<PrivateRoute />}>
        <Route path="/profile" element={<Profile />} />
        <Route path="/checkout/:clientSecret?" element={<Checkout />} />
        <Route path="/booking-history" element={<BookHistory />} />
        <Route path="/booking-history/:id" element={<BookingConfirm />} />
        <Route path="/seatingRequests/:id" element={<SeatingRequest />} />
        <Route path="/seatingRequests" element={<SeatingRequestList />} />
      </Route>
      <Route
        path="/create-seat-map/:token?/:ticketSetupId?"
        element={<CreateSeatMap />}
      />
      <Route path="chatSupport" element={<ChatSupport />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="terms-of-use" element={<TermsOfUse />} />
      <Route path="terms-and-conditions" element={<TermsConditions />} />
      <Route path="purchase-terms" element={<PurchaseTerms />} />
      <Route path="create-events" element={<CreateEvents />} />
    </Routes>
  );
};

export default Root;
