import React from "react";
import "./index.css";

const HomeBanner = ({ head, img, img2, overlayClass }) => {
  return (
    <div
      className=" mx-auto md:mt-[3rem] mt-[3rem]  rounded-md sm:rounded-lg md:rounded-2xl flex
     bg-[black] overflow-hidden 
    items-center justify-center relative 
      border sm:px-0 h-[3rem] sm:h-[5rem] lg:h-[6rem] 
      xl:h-[8rem] md:w-[85%] w-[97%] sm:w-[90%]"
    >
      <div className="animate-move absolute bottom-0 w-full">
        <img className="w-full  h-full " src={img} alt="Image not Found" />
        <img className="w-full h-full " src={img2} alt="Image not Found" />
      </div>
      <div className={` absolute ${overlayClass ?? ""} h-full w-full`}></div>
      <div
        className="absolute z-20 items-center w-[90%] gap-[23px] 
            sm:gap-[45px] md:gap-[70px]  lg:gap-[100px] pe-4 flex"
      >
        <div
          className="text-[20px] sm:text-[33px] md:text-[45px] 
                lg:text-[46px] xl:text-[60px]  font-mono 
                font-bold  text-white"
        >
          TICKE
          <span className="text-[#C4242B]">TI</span>NI
        </div>
        <div
          className="text-[10px] sm:text-[18px] md:text-[20px] 
                lg:text-[28px] xl:text-[38px] font-semibold 
                whitespace-nowrap text-white"
        >
          {head}
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
