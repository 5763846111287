import React, { useState } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import Root from "./routes/root";
import { LiveChatWidget, useWidgetState } from "@livechat/widget-react";
import { IoChatboxOutline } from "react-icons/io5";

import "./App.css";
import Navbar from "./components/shared/navbar";
import Footer from "./components/shared/footer";
import UserProvider, { useUserContext } from "./context/User";
import BtnLoader from "./components/shared/btnLoader";

const LiveChat = () => {
  const [showChat, setShowChat] = useState(false);
  const { user, loggedIn } = useUserContext();
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  const widgetState = useWidgetState();
  const licenseKey = process.env.REACT_APP_LIVECHAT_KEY;

  const hideWidgetForRoutes = ["chatSupport", "create-seat-map"];

  return hideWidgetForRoutes.includes(pathname) ||
    !loggedIn ? null : showChat ? (
    <>
      <LiveChatWidget
        license={licenseKey}
        visibility={"maximized"}
        customerName={user?.name || ""}
        customerEmail={user?.email || ""}
        group={"4"}
      />
      {!widgetState && (
        <button
          className="fixed bottom-5 right-5 z-50 rounded-full bg-blue-600 h-14 w-14 flex items-center justify-center"
          onClick={() => setShowChat(true)}
        >
          <BtnLoader />
        </button>
      )}
    </>
  ) : (
    <button
      className="fixed bottom-5 right-5 z-50 rounded-full bg-blue-600 h-14 w-14 flex items-center justify-center"
      onClick={() => setShowChat(true)}
    >
      <IoChatboxOutline className="text-white text-3xl" />
    </button>
  );
};

function App() {
  return (
    <UserProvider>
      <div className="App">
        <BrowserRouter>
          <Navbar />
          <Root />
          <LiveChat />
          <Footer />
        </BrowserRouter>
      </div>
    </UserProvider>
  );
}

export default App;
