import React, { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const AddSectionForm = ({
  onAddSection,
  onClose,
  editingSection,
  editingIndex,
  editSectionHandler,
}) => {
  const [sectionName, setSectionName] = useState("");
  const [price, setPrice] = useState("");
  const [rows, setRows] = useState("");
  const [columns, setColumns] = useState("");
  const [startChar, setStartChar] = useState("");

  useEffect(() => {
    if (editingSection) {
      setSectionName(editingSection.sectionName);
      setPrice(editingSection.price);
      setStartChar(editingSection.rows[0].label);
    }
  }, editingSection);

  const reset = () => {
    setSectionName("");
    setPrice("");
    setRows("");
    setColumns("");
    setStartChar("");
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (price < 1.5) return alert("Minimum ticket price is 1.5$");
    editingSection
      ? editSectionHandler(editingIndex, { sectionName, price, startChar })
      : onAddSection({ sectionName, price, rows, columns, startChar });
    reset();
  };

  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  return (
    <div className="relative bg-white p-6 rounded shadow-lg">
      <AiFillCloseCircle
        className="absolute top-0 right-0 rounded-full cursor-pointer"
        color="red"
        size={20}
        onClick={reset}
      />
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center space-y-4 w-64"
      >
        <input
          type="text"
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
          placeholder="Section Name"
          className="p-2 border rounded w-full"
          required
        />
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Price (Minimum 1.5$)"
          className="p-2 border rounded w-full"
          required
        />
        {!editingSection && (
          <input
            type="number"
            value={rows}
            onChange={(e) => setRows(e.target.value)}
            placeholder="Number of rows in this section"
            className="p-2 border rounded w-full"
            required
          />
        )}
        {!editingSection && (
          <input
            type="number"
            value={columns}
            onChange={(e) => setColumns(e.target.value)}
            placeholder="Number of seats in each row"
            className="p-2 border rounded w-full"
            required
          />
        )}
        <input
          type="text"
          value={startChar}
          onChange={(e) =>
            letters.includes(e.target.value)
              ? setStartChar(e.target.value.toUpperCase())
              : null
          }
          placeholder="First row name (A, B, C...)"
          className="p-2 border rounded w-full"
          required
        />
        <div className="flex justify-center w-full">
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
          >
            {editingSection ? "Save" : "Add Section"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddSectionForm;
