import React, { useEffect } from "react";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import T1 from "./images/t1.webp";
import T2 from "./images/t2.webp";

const CreateEvents = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const androidUrl =
    "https://play.google.com/store/apps/details?id=com.tickettini";
  const iosUrl = "https://apps.apple.com/us/app/ticketini/id6445999116";

  return (
    <section className="min-h-[90vh] bg-white  flex flex-col md:flex-row justify-center items-center text-center md:text-left">
      <div className="hidden md:flex w-1/2 justify-around items-center">
        <img
          src={T1}
          className="w-5/12 h-auto object-cover rounded-lg"
          alt="Event image one"
        />
        <img
          src={T2}
          className="w-5/12 h-auto object-cover rounded-lg"
          alt="Event image two"
        />
      </div>
      <div className="md:hidden w-full px-2">
        <img
          src={T1}
          className="w-full h-auto object-cover rounded-lg mt-4"
          alt="Event image one"
        />
        <img
          src={T2}
          className="w-full h-auto object-cover rounded-lg mt-4"
          alt="Event image two"
        />
      </div>
      <div className="w-full md:w-1/2 my-12">
        <h1 className="font-poppins font-bold text-gray-800 text-5xl mb-4 text-center">
          Ticketini
        </h1>
        <p className="text-gray-700 font-semibold text-lg text-center">
          Ticketini is a mobile box office that allows users to create events,
          sell tickets, and scan tickets for admission, all from your mobile
          device. Create events like concerts, shows, plays, musicals, sports
          matchups, fundraisers, and more, all from the convenience of your
          mobile device.
        </p>
        <p className="text-gray-800 font-bold text-xl text-center mt-4">
          Download our app and start creating your events.
        </p>
        <div className="flex justify-center my-4 items-center">
          <a
            href={androidUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-5"
          >
            <FaGooglePlay size={28} style={{ color: "#000" }} />
          </a>
          <a
            href={iosUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="mr-5"
          >
            <FaApple size={32} style={{ color: "#000" }} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default CreateEvents;
