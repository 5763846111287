import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  limit,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import { functions } from "../../firebase";
import EventBanner from "./eventBanner";
import AboutEvent from "./aboutEvent";
import EventTimeSlotDiv from "./eventTimeSlot";
import CalendarComponent from "./calender";
import { firestore } from "../../firebase";
import Loader from "../../components/shared/loader";
import Slider from "../../components/shared/slider";
import TicketTypePopUp from "../../components/popups/ticketTypePopUp";
import { useUserContext } from "../../context/User";
import LoginPopup from "../../components/popups/loginPopup";
import { maxTicketAllowed } from "../../constants";

function groupByDate(events) {
  if (!events?.length) return {};
  const grouped = {};
  events.forEach((event) => {
    const date = new Date(event.startDate).toLocaleDateString("en-CA");
    if (!grouped[date]) {
      grouped[date] = [];
    }
    grouped[date].push(event);
  });
  return grouped;
}

const SingleEvent = () => {
  const [similarEvents, setSimilarEvents] = useState([]);
  const [data, setData] = useState({});
  const [shows, setShows] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedShow, setSelectedShow] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [refetch, setRefetch] = useState(0);

  const navigate = useNavigate();
  const { id } = useParams();
  const { loggedIn } = useUserContext();
  const limitNum = 10;

  useEffect(() => {
    setShowLoader(true);
    const docRef = doc(firestore, "Events", id);
    getDoc(docRef)
      .then((snap) => {
        if (snap.exists()) setData(snap.data());
      })
      .catch((err) => console.log("err", err))
      .finally(() => setShowLoader(false));
  }, [id]);

  const fetchDocs = async () => {
    try {
      var snap = await getDocs(
        query(
          collection(firestore, "Shows"),
          where("eventId", "==", id),
          orderBy("startDate", "asc")
        )
      );
      const newData = snap.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          date: new Date(data.createdAt),
          id: doc.id,
        };
      });
      setShows(
        groupByDate(
          newData.filter(
            (item) =>
              !!item?.ticketSetup?.length &&
              new Date(item.startDate).valueOf() > Date.now()
          )
        )
      );
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchDocs();
  }, [id, refetch]);

  useEffect(() => {
    if (!Object.keys(shows).length) return;
    setSelectedDate(Object.keys(shows)[0]);
  }, [shows]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  const fetchEvent = async () => {
    try {
      var snap = await getDocs(
        query(
          collection(firestore, "Events"),
          where("status", "==", "published"),
          where("endDate", ">", Date.now()),
          orderBy("endDate", "desc"),
          limit(limitNum)
        )
      );
      if (snap.docs.length) {
        const newData = snap.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            date: new Date(data.createdAt),
            id: doc.id,
          };
        });
        setSimilarEvents(newData);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchEvent();
  }, [refetch]);

  const onCheckout = async (form, amount) => {
    if (showLoader) return;
    const totalTickets = form.reduce((total, item) => {
      return total + Number(item?.volume || 0);
    }, 0);
    if (totalTickets > maxTicketAllowed)
      return alert(`You can book a maximum of ${maxTicketAllowed} tickets.`);

    var exceedingCat = selectedShow.ticketSetup.find((item) => {
      const i = form.find((i) => i.category == item.category);
      return i ? i.volume > item.available : false;
    });
    if (exceedingCat)
      return alert(
        `Only ${exceedingCat.available} tickets are available in ${exceedingCat.category} category.`
      );

    try {
      setShowLoader(true);
      const res = await httpsCallable(
        functions,
        "createStripeCheckout"
      )({
        amount,
        vendorId: data.stripeId,
        businessId: data.userId,
        eventId: id,
        showId: selectedShow.id,
        seatInfo: form.filter((item) => !!item.volume),
        isGeneralAdmission: true,
      });
      if (res?.data?.clientSecret)
        navigate(`/checkout/${res.data.clientSecret}`);
      else alert("Error. Please try again.");
    } catch (error) {
      console.log("err", error);
    } finally {
      setShowLoader(false);
      setRefetch(refetch + 1);
      setSelectedShow(null);
    }
  };

  useEffect(() => {
    if (selectedShow) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "unset"; // Enable scrolling
    }
    return () => (document.body.style.overflow = "unset");
  }, [selectedShow]);

  const onSelectShow = (item) => {
    if (item.isGeneralAdmission) setSelectedShow(item);
    else navigate(`${item.id}`);
  };

  return showLoader ? (
    <Loader />
  ) : Object.keys(data).length ? (
    <>
      <EventBanner data={data} eventId={id} />
      <AboutEvent data={data} />
      <CalendarComponent
        shows={shows}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <EventTimeSlotDiv
        data={selectedDate ? shows[selectedDate] : []}
        setSelectedShow={
          loggedIn ? (val) => onSelectShow(val) : () => setShowLoginPopup(true)
        }
      />
      <div className="sm:mt-[4] mx-auto w-[95%] md:mt-16">
        <Slider
          data={similarEvents}
          sliderTitle="You might also like"
          navigation={true}
          extraClass="mb-5"
          styleClass="mb-20"
        />
      </div>
      {!!selectedShow && (
        <TicketTypePopUp
          onclose={() => setSelectedShow(null)}
          selectedShow={selectedShow}
          onCheckout={onCheckout}
          showLoader={showLoader}
        />
      )}
      {showLoginPopup && (
        <LoginPopup
          onClose={() => setShowLoginPopup(false)}
          open={showLoginPopup}
          title="Login to continue"
        />
      )}
    </>
  ) : null;
};

export default SingleEvent;
