import firstSliderImg1 from "./firstslider1.jpg";
import firstSliderImg4 from "./bg3.jpg";
import firstSliderImg7 from "./bg6.jpg";
import firstSliderImg8 from "./bg7.jpg";
import firstSliderImg9 from "./bg8.jpg";

export const firstSliderimageData = [
  {
    bannerImage: firstSliderImg1,
  },
  {
    bannerImage: firstSliderImg7,
  },
  {
    bannerImage: firstSliderImg4,
  },
  {
    bannerImage: firstSliderImg7,
  },
  {
    bannerImage: firstSliderImg8,
  },
  {
    bannerImage: firstSliderImg1,
  },
  {
    bannerImage: firstSliderImg7,
  },
  {
    bannerImage: firstSliderImg8,
  },
  {
    bannerImage: firstSliderImg9,
  },
  {
    bannerImage: firstSliderImg1,
  },
];
