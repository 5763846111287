import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../../firebase";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";

import EventCard from "../../components/shared/eventCard";
import Loader from "../../components/shared/loader";

const Events = () => {
  const [eventData, setEventData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [page, setPage] = useState(0);
  const [dataToShow, setDataToShow] = useState([]);

  const { tag } = useParams();
  const maxDocLimit = 20;

  const fetchEvents = async () => {
    try {
      setShowLoader(true);
      var eventQuery = query(
        collection(firestore, "Events"),
        where("status", "==", "published"),
        where("endDate", ">", Date.now()),
        orderBy("endDate", "desc"),
        limit(maxDocLimit)
      );
      if (tag) eventQuery = query(eventQuery, where("eventType", "==", tag));
      var snap = await getDocs(eventQuery);

      if (snap.docs.length) {
        const newData = snap.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            date: new Date(data.startDate),
            id: doc.id,
          };
        });
        setEventData(newData);
        if (snap.docs.length == maxDocLimit)
          setLastDoc(snap.docs[snap.docs.length - 1]);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchMoreEvents = async () => {
    if (eventData.length > page + maxDocLimit) setPage(page + maxDocLimit);
    else {
      try {
        setShowLoader(true);
        var eventQuery = query(
          collection(firestore, "Events"),
          where("status", "==", "published"),
          where("endDate", ">", Date.now()),
          orderBy("endDate", "desc"),
          startAfter(lastDoc),
          limit(maxDocLimit)
        );
        if (tag) eventQuery = query(eventQuery, where("eventType", "==", tag));
        var snap = await getDocs(eventQuery);

        if (snap.docs.length) {
          const newData = snap.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              date: new Date(data.startDate),
              id: doc.id,
            };
          });
          setEventData([...eventData, ...newData]);
          setPage(page + maxDocLimit);
          if (snap.docs.length == maxDocLimit)
            setLastDoc(snap.docs[snap.docs.length - 1]);
          else setLastDoc(null);
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setShowLoader(false);
      }
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    if (!eventData.length) return;
    var data = eventData.slice(page, page + maxDocLimit);
    setDataToShow(data);
  }, [eventData, page, lastDoc]);

  return showLoader ? (
    <Loader />
  ) : (
    <div className="min-h-[65vh]">
      <p className="text-center font-semibold text-[18px] mt-4">
        {tag || "All"} Events
      </p>
      <div className="flex flex-wrap justify-center md:justify-start my-4">
        {dataToShow.map((item) => (
          <div className="w-[48%] md:w-[250px] mx-[2px] md:mx-4">
            <EventCard item={item} />
          </div>
        ))}
      </div>
      <div className="flex justify-center mb-4">
        {!!page && (
          <button
            className="bg-gray-600 hover:bg-gray-800 py-2 px-4 rounded mx-2 text-white"
            onClick={() => setPage(page - maxDocLimit)}
          >
            Prev
          </button>
        )}
        {(!!lastDoc || eventData.length > page + maxDocLimit) && (
          <button
            className="bg-gray-600 hover:bg-gray-800 py-2 px-4 rounded mx-2 text-white"
            onClick={fetchMoreEvents}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default Events;
