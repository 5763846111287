import React, { useState } from "react";
import Moment from "react-moment";

const EventTimeSlotDiv = ({ data, setSelectedShow }) => {
  return (
    <div className="w-[100%] ">
      <div
        className="w-[100%] px-6 sm:w-[85%] py-16 mx-auto flex flex-wrap 
      justify-center gap-3 sm:gap-4 sm:px-2"
      >
        {data?.length
          ? data.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setSelectedShow(item)}
                  className="w-[100px] cursor-pointer sm:w-[110px] text-[14px] py-[10px] text-green-400 rounded-md border border-gray-400"
                >
                  <p className="text-center leading-4">
                    <Moment format="h:mm A">{item.startDate}</Moment>
                    <br />
                    -
                    <br />
                    <Moment format="h:mm A">{item.endDate}</Moment>
                  </p>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default EventTimeSlotDiv;
