import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import { firestore } from "../../firebase";
import { useUserContext } from "../../context/User";
import { adminAccounts } from "../../constants";

function SeatingRequestList() {
  const [requestData, setRequestData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [, setTick] = useState(0);

  const { user } = useUserContext();
  const isAdmin = adminAccounts.includes(user?.userId);

  const getData = async () => {
    try {
      setShowLoader(true);
      var snap = await getDocs(
        query(
          collection(firestore, "TicketSetup"),
          where("requestedAdmin", "==", true),
          orderBy("createdAt", "desc")
        )
      );
      if (snap.docs.length) {
        const newData = snap.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
          };
        });
        setRequestData(newData);
        setTick((i) => i + 1);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (isAdmin) getData();
  }, [isAdmin]);

  return (
    <div className="flex flex-col items-center">
      {isAdmin ? (
        <>
          {" "}
          <div className="w-full p-4">
            <p className="text-lg font-bold text-center">
              Seating Structure Requests
            </p>
          </div>
          <div className="w-full flex justify-center min-h-[60vh]">
            <div className="w-3/4">
              {requestData.map((item) => (
                <div key={item.id} className="p-4">
                  <Link to={`/seatingRequests/${item.id}`}>
                    <div
                      className="border border-black p-4 w-full max-w-[400px] mx-auto text-center rounded-lg"
                      style={{ cursor: "pointer" }}
                    >
                      <p className="mb-2 font-semibold">Name: {item.name}</p>
                      <p>
                        Date:{" "}
                        <Moment format="DD-MM-YYYY">{item.createdAt}</Moment>
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="w-full min-h-[60vh] p-4">
          <p className="text-lg font-bold text-center">Admin access required</p>
        </div>
      )}
    </div>
  );
}

export default SeatingRequestList;
