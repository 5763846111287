import React from "react";

const AboutEvent = ({ data }) => {
  return (
    <div className="w-[100%] pb-4">
      <div className="w-[100%] sm:w-[85%] mb-0 sm:mb-10 mx-auto">
        <div className="w-[85%] mx-auto sm:mx-0 text-center sm:text-left sm:border-b py-8">
          <h1 className="text-[24px] font-[700] py-3 leading-[36px]">About </h1>
          <p className="text-[17px] sm:text-[14px] text-grey-500 font-[400] py-2 leading-6">
            {data?.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutEvent;
