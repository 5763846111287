import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const EventCard = ({ item, disableLink, isBanner, titleClass }) => {
  return (
    <>
      <div
        className={`w-[100%] h-[100%] overflow-hidden image-container ${
          isBanner ? "" : "slider-border"
        }`}
      >
        {disableLink ? (
          <img src={item.bannerImage} className={`w-[100%] h-[100%] `} alt="" />
        ) : (
          <Link
            style={{ textDecorationLine: "none" }}
            to={`/single-event/${item.id}`}
          >
            <img
              src={item.bannerImage}
              className={`w-[100%] h-[100%]`}
              alt=""
            />
          </Link>
        )}
      </div>

      {!isBanner && (
        <div className="py-2 w-[100%] text-left ">
          <div
            className={`text-black font-bold ${
              titleClass ?? ""
            } text-[13px] whitespace-nowrap sm:text-[18px] truncate overflow-clip`}
          >
            {item.name}
          </div>
          <div className="text-gray-500 text-[14px] sm:text-[16px] font-semibold">
            {moment(item.date).format("ddd, D MMM")}
          </div>
        </div>
      )}
    </>
  );
};

export default EventCard;
