import React from "react";
import {
  MdArrowUpward,
  MdArrowDownward,
  MdDelete,
  MdEdit,
} from "react-icons/md";

const Seat = ({ number, notEmptySpace, onPress }) => {
  const seatClasses = `w-8 h-8 flex items-center justify-center mx-1 rounded cursor-pointer ${
    notEmptySpace ? "bg-green-500" : "hover:bg-gray-200"
  }`;

  return (
    <div className={seatClasses} onClick={onPress}>
      {notEmptySpace ? number : ""}
    </div>
  );
};

const Row = ({ row, rowIndex, onRowClick, onSeatClick }) => {
  return (
    <div key={rowIndex} className="flex items-center my-2">
      <div
        className="w-8 h-8 flex items-center justify-center mx-1 rounded border border-gray-200 cursor-pointer"
        onClick={onRowClick}
      >
        {row.label}
      </div>
      {row.seats.map((seat, seatIndex) => (
        <Seat
          key={seatIndex}
          {...seat}
          onPress={() => onSeatClick(seatIndex)}
        />
      ))}
    </div>
  );
};

const SeatMapContainer = ({
  section,
  onMoveUp,
  onMoveDown,
  onDeleteSection,
  onEdit,
  toggleColumnAvailability,
  toggleRowAvailability,
  toggleSeatAvailability,
  addNewColumn,
  onAddRow,
  index,
  length,
}) => {
  return (
    <div className="seat-map-container my-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-bold">
            {section.sectionName}: {section.price}$
          </h2>
          <div className="w-5" />
          {index > 0 && (
            <button
              onClick={() => onMoveUp(index)}
              className="text-gray-700 hover:text-gray-500 p-1"
              aria-label="Move Section Up"
            >
              <MdArrowUpward className="h-6 w-6" />
            </button>
          )}
          {index < length - 1 && (
            <button
              onClick={() => onMoveDown(index)}
              className="text-gray-700 hover:text-gray-500 p-1"
              aria-label="Move Section Down"
            >
              <MdArrowDownward className="h-6 w-6" />
            </button>
          )}
          <button
            onClick={onEdit}
            className="text-gray-600 hover:text-gray-800 p-1 ml-2"
          >
            <MdEdit className="h-6 w-6" />
          </button>
          <button
            onClick={onDeleteSection}
            className="text-red-600 hover:text-red-800 p-1 ml-2"
          >
            <MdDelete className="h-6 w-6" />
          </button>
        </div>
      </div>
      <div className="flex mb-4 items-center ml-10">
        {section.rows[0]?.seats.map((_, columnIndex) => (
          <div
            className="w-8 h-8 flex items-center justify-center mx-1 rounded border border-gray-200 cursor-pointer"
            onClick={() => toggleColumnAvailability(columnIndex)}
          ></div>
        ))}
        <div
          className="w-8 h-8 flex items-center justify-center mx-1 rounded border border-gray-200 cursor-pointer"
          onClick={() => addNewColumn(index)}
        >
          +
        </div>
      </div>
      {section.rows.map((row, rowIndex) => (
        <Row
          row={row}
          rowIndex={rowIndex}
          onRowClick={() => toggleRowAvailability(rowIndex)}
          onSeatClick={(seatIndex) =>
            toggleSeatAvailability(rowIndex, seatIndex)
          }
        />
      ))}
      <div
        className="w-8 h-8 flex items-center justify-center mx-1 rounded border border-gray-200 cursor-pointer"
        onClick={() => onAddRow(index)}
      >
        +
      </div>
    </div>
  );
};

export default SeatMapContainer;
