import React from "react";

const Seat = ({ number, notEmptySpace, isAvailable, isSelected, onPress }) => {
  const seatClasses = `w-8 h-8 flex items-center justify-center mx-1 rounded ${
    notEmptySpace
      ? isSelected
        ? "bg-green-500 cursor-pointer"
        : isAvailable
        ? "border border-green-500 cursor-pointer text-green-500 font-thin"
        : "bg-gray-100 cursor-not-allowed"
      : ""
  }`;

  return (
    <div className={seatClasses} onClick={onPress}>
      {notEmptySpace ? number : ""}
    </div>
  );
};

const Row = ({ row, rowIndex, sectionIndex, onSeatClick, selectedSeats }) => {
  return (
    <div key={rowIndex} className="flex items-center my-2">
      <div className="w-8 h-8 flex items-center justify-center mx-1">
        {row.label}
      </div>
      {row.seats.map((seat, seatIndex) => (
        <Seat
          key={seatIndex}
          {...seat}
          isSelected={JSON.stringify(selectedSeats).includes(
            JSON.stringify({ sectionIndex, rowIndex, seatIndex })
          )}
          onPress={() =>
            seat.notEmptySpace && seat.isAvailable
              ? onSeatClick(sectionIndex, rowIndex, seatIndex)
              : null
          }
        />
      ))}
    </div>
  );
};

const SeatMapContainer = ({
  section,
  sectionIndex,
  onSeatClick,
  selectedSeats,
}) => {
  return (
    <div className="seat-map-container my-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-bold">
            {section.sectionName}: {section.price}$
          </h2>
        </div>
      </div>
      {section.rows.map((row, rowIndex) => (
        <Row
          row={row}
          rowIndex={rowIndex}
          sectionIndex={sectionIndex}
          onSeatClick={onSeatClick}
          selectedSeats={selectedSeats}
        />
      ))}
    </div>
  );
};

export default SeatMapContainer;
