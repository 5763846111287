import React, { useState, useEffect, useRef, useContext } from "react";
import { getDoc, onSnapshot, doc, setDoc } from "firebase/firestore";

import { auth } from "../firebase";
import { firestore } from "../firebase";

export const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(true);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState({});

  const userSubscriberRef = useRef();

  const onAuthStateChanged = async (user) => {
    if (user) {
      const id = user?.uid;
      const docRef = doc(firestore, "Users", id);
      const snap = await getDoc(docRef);
      if (!snap.exists()) {
        const date = new Date();
        const data = user?.providerData?.[0];
        const payload = {
          email: data?.email ? data.email.toLowerCase() : "",
          phone: data?.phoneNumber ? data?.phoneNumber.slice(-10) : "",
          userId: id,
          createdAt: date.valueOf(),
          authMethod: data?.providerId,
          authAccount: data?.phoneNumber
            ? data?.phoneNumber.slice(-10)
            : data?.email,
          role: "consumer",
        };
        try {
          await setDoc(docRef, payload);
        } catch (err) {
          console.log("err", err);
        }
      }
      setUserId(id);
    } else {
      setLoggedIn(false);
      setUserId("");
      setUser(null);
      if (userSubscriberRef.current) userSubscriberRef.current();
    }
  };

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);

    return () => {
      subscriber();
    };
  }, []);

  useEffect(() => {
    if (!userId) return;
    const docRef = doc(firestore, "Users", userId);
    const userSubscriber = onSnapshot(docRef, (snap) => {
      if (snap.exists) {
        setUser(snap.data());
      }
      setLoggedIn(true);
    });
    userSubscriberRef.current = userSubscriber;
    return userSubscriber;
  }, [userId]);

  const logoutHandler = (navigate, callback = () => {}) => {
    auth
      .signOut()
      .then(() => {
        navigate("/");
        callback();
      })
      .catch((err) => console.log("err", err));
  };

  return (
    <UserContext.Provider
      value={{
        loggedIn,
        user,
        logoutHandler,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);

export default UserProvider;
