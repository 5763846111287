export const getAddressString = (addr, withName = false) => {
  if (!addr) return "";
  return `${withName ? addr.name + ", " : ""}${addr.detail}, ${
    addr?.extraDetail ? addr.extraDetail + ", " : ""
  }${addr.city}, ${addr.state} (${addr.zip})`;
};

export const calculateDaysDifference = (isoDateString) => {
  const dateFromISO = new Date(isoDateString);
  const currentDate = new Date();

  const differenceInMilliseconds = dateFromISO - currentDate;
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  return Math.floor(differenceInDays); // Use Math.floor to round down to the nearest whole number
};
