import React, { useEffect, useState } from "react";
import { LiveChatWidget } from "@livechat/widget-react";
import { useLocation } from "react-router-dom";
import Loader from "../../components/shared/loader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ChatSupport = () => {
  const [showLoader, setShowLoader] = useState(true);
  const query = useQuery();
  const userName = query.get("name");
  const userEmail = query.get("email");

  const sendMessageToReactNative = (type, content) => {
    const message = { type, content };
    if (window?.ReactNativeWebView?.postMessage)
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
  };

  return (
    <div>
      {showLoader ? (
        <Loader dark={false} />
      ) : (
        <div className="h-[100vh] flex items-center justify-center text-[18px]">
          Click on icon below to expand chat.
        </div>
      )}
      <LiveChatWidget
        onNewEvent={(e) =>
          e.type == "message" && e.author.type == "agent"
            ? sendMessageToReactNative(e.type, "")
            : null
        }
        onReady={() => setShowLoader(false)}
        license="16807020"
        customerName={userName || ""}
        customerEmail={userEmail || ""}
        visibility="maximized"
        group="3"
      />
    </div>
  );
};

export default ChatSupport;
