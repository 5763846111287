import "swiper/css/navigation";
import "swiper/css/effect-fade";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/hash-navigation";
import "swiper/css";
import "./slider.css";
import Moment from "react-moment";

import { Navigation, EffectFade, Autoplay, Pagination } from "swiper/modules";
import EventCard from "../eventCard";

const Slider = ({
  data,
  sliderTitle,
  titleClass,
  styleClass,
  extraClass,
  isBanner,
  disableLink,
  autoplay = false,
  tag,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`w-[100%]  flex  flex-col items-center text-ellipsis  ${
          styleClass || ""
        }`}
      >
        {!isBanner && !!sliderTitle && (
          <div
            className={`title text-black font-bold  text-[18px]   ${
              extraClass ?? ""
            } sm:text-[19px] md:text-[22px] lg:text-[24px] flex justify-start items-center   sm:w-[90%] w-[90%] md:w-[90%] truncate`}
          >
            <span>{sliderTitle}</span>
            <span
              className="font-semibold text-[15px] ml-4 mt-1 hover:cursor-pointer"
              onClick={() => navigate(tag ? `events/${tag}` : "events")}
            >
              View all
            </span>
          </div>
        )}

        <div
          className={` ${isBanner ? "w-[100%]" : "w-[90%]"} overflow-hidden ${
            isBanner ? "h-[350px]" : "md:h-[450px] h-[250px]"
          } flex `}
        >
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-navigation-bg": "#808080",
              "--swiper-navigation-size": "35px",
              "--swiper-button-prev": "displayNone",
              " --swiper-navigation-sides-offset": "-10px",
            }}
            breakpoints={{
              1200: { slidesPerView: isBanner ? 1 : 5 },
              992: { slidesPerView: isBanner ? 1 : 4 },
              640: { slidesPerView: isBanner ? 1 : 3 },
              768: { slidesPerView: isBanner ? 1 : 3 },
              300: { slidesPerView: isBanner ? 1 : 3 },
            }}
            effect={isBanner ? "fade" : undefined}
            autoplay={autoplay}
            centeredSlides={false}
            slidesPerGroupSkip={1}
            navigation={isBanner ? false : true}
            modules={
              isBanner
                ? [EffectFade, Autoplay, Navigation, Pagination]
                : [Autoplay, Navigation]
            }
            // pagination={{
            //   clickable: true,
            // }}
            spaceBetween={30}
            className="mySwiper nav-size"
          >
            <div className={`flex  w-[100%] bg-transparent `}>
              {data?.map((item, index) => (
                <SwiperSlide key={index} className="flex flex-col">
                  <EventCard
                    item={item}
                    disableLink={disableLink}
                    isBanner={isBanner}
                    titleClass={titleClass}
                  />
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Slider;
