import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUserContext } from "../context/User";

const PrivateRoute = () => {
  const { loggedIn } = useUserContext();
  const location = useLocation();

  if (!loggedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default PrivateRoute;
