import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import Moment from "react-moment";

import { firestore, functions } from "../../firebase";
import BtnLoader from "../../components/shared/btnLoader";
import SeatMapContainer from "./seatMapContainer";
import { httpsCallable } from "firebase/functions";
import { maxTicketAllowed } from "../../constants";

const TicketOptions = () => {
  const [eventInfo, setEventInfo] = useState({});
  const [showInfo, setShowInfo] = useState({});
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [total, setTotal] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [refetch, setRefetch] = useState(0);

  const { eventId, showId } = useParams();
  const navigate = useNavigate();
  const ticketSetup = showInfo?.ticketSetup || [];

  useEffect(() => {
    if (!eventId || !showId) return;
    setLoadingData(true);
    const eventRef = doc(firestore, "Events", eventId);
    const showRef = doc(firestore, "Shows", showId);
    getDoc(eventRef)
      .then((snap) => setEventInfo(snap.data()))
      .then(() => getDoc(showRef))
      .then((showSnap) => setShowInfo(showSnap.data()))
      .catch((err) => console.log("err", err))
      .finally(() => setLoadingData(false));
  }, [refetch]);

  const handleSeatClick = (sectionIndex, rowIndex, seatIndex) => {
    const seat = { sectionIndex, rowIndex, seatIndex };
    const seatExists = selectedSeats.some(
      (s) =>
        s.sectionIndex === sectionIndex &&
        s.rowIndex === rowIndex &&
        s.seatIndex === seatIndex
    );

    if (seatExists) {
      setSelectedSeats(
        selectedSeats.filter(
          (s) =>
            s.sectionIndex !== sectionIndex ||
            s.rowIndex !== rowIndex ||
            s.seatIndex !== seatIndex
        )
      );
    } else {
      setSelectedSeats([...selectedSeats, seat]);
    }
  };

  useEffect(() => {
    var newTotal = 0;
    selectedSeats.forEach((seat) => {
      newTotal += Number(ticketSetup[seat.sectionIndex].price);
    });
    setTotal(newTotal);
  }, [selectedSeats]);

  const getSeatInfo = () => {
    var seatInfo = [];
    selectedSeats.forEach((seat) => {
      const section = ticketSetup[seat.sectionIndex];
      var ind = seatInfo.findIndex(
        (item) => item.category == section.sectionName
      );
      if (ind < 0)
        seatInfo.push({
          category: section.sectionName,
          price: section.price,
          volume: 1,
          detail: `${section.rows[seat.rowIndex].label}-${seat.seatIndex + 1}`,
        });
      else {
        seatInfo[ind].volume += 1;
        seatInfo[ind].detail = seatInfo[ind].detail.concat(
          `,${section.rows[seat.rowIndex].label}-${seat.seatIndex + 1}`
        );
      }
    });
    return seatInfo;
  };

  const onCheckout = async () => {
    if (showLoader) return;
    if (selectedSeats.length > maxTicketAllowed)
      return alert(`You can book a maximum of ${maxTicketAllowed} seats`);

    try {
      setShowLoader(true);

      const payload = {
        amount: total,
        vendorId: eventInfo.stripeId,
        businessId: eventInfo.userId,
        eventId,
        showId,
        seatInfo: getSeatInfo(),
        seatIndexes: selectedSeats,
        isGeneralAdmission: false,
      };
      const res = await httpsCallable(
        functions,
        "createStripeCheckout"
      )(payload);

      if (res?.data?.clientSecret)
        navigate(`/checkout/${res.data.clientSecret}`);
      else alert("Error");
    } catch (error) {
      console.log("err", error);
    } finally {
      setShowLoader(false);
      setRefetch((i) => i + 1);
      setSelectedSeats([]);
    }
  };

  return (
    <div className="min-h-[60vh]">
      {loadingData ? (
        <div className="flex items-center justify-center w-full p-6">
          <BtnLoader stroke="black" size={25} />
        </div>
      ) : (
        <div>
          <div className="bg-gray-200 p-4 flex items-center">
            <button className="mr-4" onClick={() => navigate(-1)}>
              <MdArrowBack className="h-6 w-6" />
            </button>
            <h1 className="text-xl font-semibold">{eventInfo.name}</h1>
            <h2 className="text-md font-semibold ml-2">
              (<Moment format="lll">{showInfo.startDate}</Moment>)
            </h2>
          </div>
          <div className="flex flex-col items-center justify-center w-full">
            {!!showInfo?.ticketSetup?.length && (
              <>
                <div className="mt-2">
                  {selectedSeats.length} seats selected
                </div>
                <div className="mt-1 text-gray-500">
                  *You can book a maximum of {maxTicketAllowed} seats.
                </div>
              </>
            )}
            {ticketSetup.map((section, index) => (
              <SeatMapContainer
                key={index}
                section={section}
                sectionIndex={index}
                onSeatClick={handleSeatClick}
                selectedSeats={selectedSeats}
              />
            ))}
            {total > 0 && (
              <div
                className="bg-[#333545] rounded-md text-white px-5 py-2 mt-4 mb-8 text-center cursor-pointer"
                onClick={onCheckout}
              >
                {showLoader ? (
                  <BtnLoader stroke="white" size={20} />
                ) : (
                  `Proceed to pay (${total}$)`
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketOptions;
