import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      className="p-2 md:py-4 md:px-8"
      dangerouslySetInnerHTML={{
        __html: `<div class="WordSection1">
      <p class="MsoNormal" style="margin:0in;text-indent:0in;line-height:107%;">
        <span style="font-size:22.5pt;line-height:107%;color:#222222;">
          Privacy Policy
        </span>
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:9.55pt; margin-left:-.25pt;"
      >
        This policy was last updated on January 10th 2024 to clarify how
        customer data will be used by our Event Partners.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:11.85pt; margin-left:0in;text-indent:0in;line-height:107%;"
      >
        <strong>
          <em>
            <span style="font-size: 17.5pt;line-height:107%;color:#222222;">
              PRIVACY POLICY FOR CUSTOMERS
            </span>
          </em>
        </strong>
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        Please read this Privacy Policy carefully as it sets out how Ticketini
        Group (&ldquo;Ticketini&rdquo;, &ldquo;we&rdquo; or &ldquo;us&rdquo;)
        uses your personal information:
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:0in; margin-left:-.25pt;"
      >
        where you are a Ticketini customer, to provide you with the Ticketini
        mobile software application (the &ldquo;App&rdquo;) and websites
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        (together with the App, collectively the &ldquo;Services&rdquo;);
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        where you are a performing artist performing at an event featured in the
        App (&ldquo;Artist&rdquo;), to provide you with services relating to our
        ticketing services and platform;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:13.15pt;margin-bottom: 19.0pt;margin-left:-.25pt;"
      >
        where you are a promoter, organiser, manager, artist, or venue where we
        are a disclosed ticketing agent selling tickets on your behalf
        (&ldquo;Event Partner&rdquo;), to provide you with services relating to
        our ticketing services and platform; and where you are a job candidate,
        to consider your employment application.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        If you have any questions about this Privacy Policy, please contact us
        at boxoffice@ticketini.io. We may change this policy from time to time
        so please check the Help Centre within the App or our website from time
        to time for any updates. If we make material changes in the way we use
        your personal information, we will notify you by posting a notice on the
        App and/or our website or sending you an email at the email address
        provided at the time of registration. This Privacy Policy applies to our
        customers, and to other individuals whose data we may process except for
        our employees, candidates, event partners and artists.
      </p>
      <p class="MsoNormal" style="margin-left:11.9pt;text-indent:-11.9pt;">
        <span style="line-height:112%;">
          1.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        ABOUT US
      </p>
      <p class="MsoNormal" style="margin-left:56.85pt;text-indent:-20.85pt;">
        <span style="line-height:112%;">
          1.1.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        Ticketini This Privacy Policy is issued on behalf of the Ticketini Inc.
        Ticketini Inc. is the data controller and responsible for this website.
      </p>
      <p class="MsoNormal" style="margin-left:56.85pt;text-indent:-20.85pt;">
        <span style="line-height:112%;">
          1.2.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        We are a disclosed ticketing agent selling tickets via the Services on
        behalf of the Event Partners.
      </p>
      <p class="MsoNormal" style="margin-left:11.9pt;text-indent:-11.9pt;">
        <span style="line-height:112%;">
          2.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        OVERVIEW
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        This Privacy Policy explains how we use your personal information and
        other data we collect when you use the Services.
      </p>
      <p class="MsoNormal" style="margin-left:11.9pt;text-indent:-11.9pt;">
        <span style="line-height:112%;">
          3.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        COLLECTED INFORMATION
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:0in; margin-left:56.85pt;text-indent:-20.85pt;"
      >
        <span style="line-height:112%;">
          3.1.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        All personal information you provide to us when registering with the
        Services, interacting with us, and any personal information generated by
        your navigation and use of the Services, will be processed and stored by
        Ticketini to manage registration and ticket sales. We also use this
        information to analyse trends in our users&apos; behaviour to help us to
        determine your preferences, habits, tastes, geographic location and
        favourite artists and events in order to maximise your use and enjoyment
        of the Services; this is the Discovery feature. Additionally, we may
        take some information from the email communications between you and
        Ticketini and store it in separate documents. Where you are an Artist or
        Event Partner, we may collect your personal information directly from
        you, your business partners, colleagues and open internet sources.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        We have further listed the ways in which we use your personal
        information at Clause 5 below.
      </p>
      <p class="MsoNormal" style="margin-left:56.85pt;text-indent:-20.85pt;">
        <span style="line-height:112%;">
          3.2.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        The types of personal information we collect may include:
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        Contact details &ndash; including your name, email address, phone
        number, postal address and date of birth;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        Payment card and billing details &ndash; when you buy tickets via the
        Services we will require payment and billing information (such as your
        address and postcode) in order to handle the processing and despatch of
        ticket orders;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        Location data &ndash; we may collect your IP address, and if you allow
        us to connect to your device&rsquo;s geolocation functionality, we may
        collect your GPS data. We will use the location data to improve
        personalised recommendations in the Services on what events are near
        you. You can turn off the App&apos;s ability to access your geolocation
        at any time through your device&rsquo;s general or privacy settings;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        Your image or recordings &ndash; we may obtain photographs, audio or
        video recordings of events listed on the Services. We may reproduce
        and/or publish your image in the App, our website, and in other
        promotional materials, social networking channels and other materials
        related to the Services;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        Your preferences &ndash; we may collect information about your purchase
        history, events you have viewed, attended or enquired about, any events
        you have joined a waiting list for, shared or invited people to attend
        and any feedback you may give or reviews you may submit;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        Music library (e.g. Spotify or Apple Music) &ndash; if you allow us to
        connect to your music library, we may collect and hold information on
        your musical interests in order to improve the personalised
        recommendations through the Services; Your interactions with us &ndash;
        for example if you send us information via email, survey, social media,
        customer service communications or other methods, this may be stored on
        our systems; and
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        Technical information &ndash; when you use the Services, Ticketini
        automatically receives and records information on its server logs from
        your browser or mobile platform, including the location, IP address,
        cookie information and details about the page you requested. We also
        collect information regarding your use of the Services, mobile device
        information and browser information.
      </p>
      <p class="MsoNormal" style="margin-left:11.9pt;text-indent:-11.9pt;">
        <span style="line-height:112%;">
          4.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        COOKIES
      </p>
      <p class="MsoNormal" style="margin-left:56.85pt;text-indent:-20.85pt;">
        <span style="line-height:112%;">
          4.1.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        Our website uses cookies to distinguish you from other users of our
        website. This helps us to provide you with a good experience when you
        browse our website and also allows us to improve our site. A cookie is a
        small file of letters and numbers that we store on your browser or the
        hard drive of your device if you agree. Cookies contain information that
        is transferred to your device hard drive.
      </p>
      <p class="MsoNormal" style="margin-left:56.85pt;text-indent:-20.85pt;">
        <span style="line-height:112%;">
          4.2.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        Our App does not use cookies.
      </p>
      <p class="MsoNormal" style="margin-left:56.85pt;text-indent:-20.85pt;">
        <span style="line-height:112%;">
          4.3.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        We use the following cookies on our website:
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        Strictly necessary cookies. These are cookies that are required for the
        operation of our website. They include, for example, cookies that enable
        you to log into secure areas of our website, use a shopping cart or make
        use of e-billing services.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        Analytical/performance cookies. They allow us to recognise and count the
        number of visitors and to see how visitors move around our website when
        they are using it. This helps us to improve the way our website works,
        for example, by ensuring that users are finding what they are looking
        for easily.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        Functionality cookies. These are used to recognise you when you return
        to our website. This enables us to personalise our content for you,
        greet you by name and remember your preferences (for example, your
        choice of language or region). Targeting cookies. These cookies record
        your visit to our website, the pages you have visited and the links you
        have followed. We will use this information to make our website and the
        advertising displayed on it more relevant to your interests. We may also
        share this information with third parties for this purpose. Where a
        marketing purpose is pursued in this regard, we will ask for your
        consent first.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        Tracking Pixels. Our website may use customised electronic marketing
        technology based on &ldquo;tracking pixels&rdquo;, which is a
        transparent graphic image or Javascript code, sometimes called a web
        beacon or tracking beacon, placed on certain pages of our website or in
        emails. The web page is served from the site&rsquo;s domain, whereas the
        image is served from the ad server&rsquo;s domain. A tracking pixel
        collects information from a webpage&rsquo;s header, such as a
        visitor&rsquo;s IP address or browser, which is forwarded to an
        advertiser (Facebook, Google, etc.) for the purpose of providing
        advertising to its customers that is based on their personal interests.
        This technology also allows the advertiser to improve those personalised
        ads so that they become more relevant to the respective user.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        Third Parties. We also work with third-party companies, including events
        organisers, that use tracking technologies to serve advertisements on
        our behalf across the Internet. These companies may collect information
        about your visits to our websites and your interaction with our
        advertising and other communications.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        4.4. You can block cookies by activating the setting on your browser
        that allows you to refuse the setting of all or some cookies. However,
        if you use your browser settings to block all cookies (including
        essential cookies) you may not be able to access all or parts of our
        site. You can opt out of cookies using the links below:
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.65pt; margin-left:-.25pt;line-height:110%;"
      >
        Microsoft Edge:
        <u>
          <span style="color: #1155CC;">
            https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
          </span>
        </u>
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.65pt; margin-left:-.25pt;line-height:110%;"
      >
        Internet Explorer:
        <u>
          <span style="color:#1155CC;">
            https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
          </span>
        </u>
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        Chrome: https://support.google.com/chrome/answer/95647?hl=en-GB
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.65pt; margin-left:-.25pt;line-height:110%;"
      >
        Firefox:
        <u>
          <span style="color:#1155CC;">
            https://support.mozilla.org/en-US/kb/delete-browsing-search-download-history-firefox
          </span>
        </u>
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:35.1pt; margin-left:-.25pt;line-height:110%;"
      >
        Safari:
        <u>
          <span style="color:#1155CC;">
            https://support.apple.com/es-es/guide/safari/manage-cookies-and-website-data-sfri11471/mac
          </span>
        </u>
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        4.6 We may combine the information we collect through cookies and pixel
        tags with other information we have collected from you, based on
        Ticketini&rsquo;s legitimate interest, as this information may be used
        to improve our websites, to personalise your online experience, to
        tailor our communications with you, to determine the effectiveness of
        our advertising, and for other internal business purposes.
      </p>
      <p class="MsoNormal" style="margin-left:11.9pt;text-indent:-11.9pt;">
        <span style="line-height:112%;">
          5.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        USE OF YOUR PERSONAL INFORMATION
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        5.1. As well as the purposes described above at Clause 3.1, we use the
        personal information we collect to:
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.15pt; margin-left:-.25pt;"
      >
        administer the App and the Services or any other purposes required in
        order to allow us to provide the Services; communicate with you via
        email, SMS, in-App notifications and push notifications about your
        purchases via the Services, including tickets (and the events they
        relate to) and any other non-ticket items; fulfil your orders for
        tickets and other non-ticket items purchased via the Services;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        provide better content, services, marketing, and support to you. For
        example, we may need to use your information to administer your account
        or provide you with information in connection with any purchases or
        events you have expressed an interest in;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:325.75pt;margin-bottom: 0in;margin-left:-.25pt;line-height:140%;"
      >
        perform our contract with Event Partners and/or Artists; provide
        information in relation to the Services; promote the events features on
        the Services;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        improve the Services, including by means of website and App data
        analytics;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        administer business relationships with Event Partners in order to allow
        us to provide the Services, including to provide customer service to
        Event Partners;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:25.9pt;margin-bottom: 0in;margin-left:-.25pt;line-height:140%;"
      >
        consider your employment application, and communicate with you in
        relation to such employment application; perform HR and recruitment
        analytics;
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.35pt; margin-left:-.25pt;"
      >
        administer and fulfil any competitions available to users via the
        Services and communicate with you about those competitions. Note that
        there may be specific terms in the competition terms and conditions
        relating to your personal information; and
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        to profile individual and demographic trends of people attending or
        showing an interest in certain events or artists. This helps us to
        provide you with recommendations of similar things you might like or
        find interesting, via the Discovery feature or otherwise. We do this by
        making assumptions based on an analysis of the information we hold,
        including the events you have viewed, waiting lists you have joined, and
        tickets you have purchased. For example, if you purchased tickets to an
        event, we might show you more events of this specific genre or type, or
        events by similar artists.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        5.2. We also use the information that we collect about you for
        operational purposes, including:
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.15pt; margin-left:-.25pt;"
      >
        to provide customer service, including to respond to your enquiries and
        fulfil any of your requests for information; carry out verification
        checks, including to verify your identity when collecting tickets at a
        venue, to check that you meet any minimum age requirements, or to check
        that any restrictions on the reselling of tickets have been complied
        with; to send you important information regarding the Services, or other
        technical notices, updates, security alerts, and support and
        administrative messages; and
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        as we believe to be necessary or appropriate under applicable law, to
        enforce our rights or to respond to requests from law enforcement and
        other government authorities.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        5.3. Where we rely on your consent to process your personal information,
        you may withdraw your consent at any time by emailing us at
        boxoffice@ticketini.io or via methods set out in this Privacy Policy. If
        you withdraw your consent, this will not affect the lawfulness of any
        processing which has taken place before you have withdrawn your consent.
      </p>
      <p class="MsoNormal" style="margin-left:11.9pt;text-indent:-11.9pt;">
        <span style="line-height:112%;">
          6.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        MARKETING
      </p>
      <p class="MsoNormal" style="margin-left:36.5pt;">
        <span style="line-height:112%;">
          6.1.
          <span style='font:7.0pt "Times New Roman";'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        We may send you marketing communications via email and push
        notifications. If you do not want to receive any marketing emails from
        us, you can edit your preferences in the communication controls centre
        in App (called &lsquo;Messages&rsquo;) or unsubscribe from emails by
        following the unsubscribe directions provided in the footer of each one.
        If you do not want to receive push notifications from us you may opt out
        by changing your preferences in the &lsquo;Messages&rsquo; control
        centre in the App or change the appropriate settings directly on your
        mobile device. Please note that to access the communication controls
        centre (&lsquo;Messages&rsquo;) you must have the App version 3.17.0 or
        above.
      </p>
      <p class="MsoNormal" style="margin-left:36.5pt;">
        <span style="line-height:112%;">
          6.2.
          <span style='font:7.0pt "Times New Roman";'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Where you opt out of receiving these marketing communications, we may
        still process your personal information for other purposes, as set out
        in this Privacy Policy.
      </p>
      <p class="MsoNormal" style="margin-left:11.9pt;text-indent:-11.9pt;">
        <span style="line-height:112%;">
          6.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        3.We may pass your details to the relevant Event Partner or third party
        so that they can contact you for marketing purposes. If you change your
        mind and do not want to receive such marketing messages, you can opt out
        by contacting that third party direct or by unsubscribing using any
        available &lsquo;unsubscribe&rsquo; link or similar in that third
        party&rsquo;s emails to you. Alternatively, to opt out of marketing
        messages for all third parties, you could edit your preferences in the
        communication controls centre in App (called &lsquo;Messages&rsquo;). We
        will notify the relevant third parties of your change in preferences,
        normally within 7 business days. Note that it may take longer for each
        third party to action your request.
      </p>
      <p class="MsoNormal" style="margin-left:11.9pt;text-indent:-11.9pt;">
        <span style="line-height:112%;">
          7.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        DISCLOSURE OF YOUR INFORMATION
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        7.1. We do not rent or sell your personal information to anyone without
        your explicit consent, but, subject to the need to perform the contract
        with you and our other business purposes set out in this Privacy Policy,
        we may share your personal information in the ways described below:
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:8.35pt;margin-bottom: 3.3pt;margin-left:-.25pt;"
      >
        Agents and suppliers &ndash; We employ other companies and individuals
        to perform functions on our behalf, including our storage providers, HR
        and recruitment service providers, communications providers, CRM
        providers, marketing providers, payment processing suppliers and data
        analytics services providers. We do not share your personal information
        with our agents or suppliers except as is necessary in order to enable
        them to provide us with a service. We may disclose your personal data to
        the following categories of third-party recipients:
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        Recipient categories and reasons
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        Hosting: We may share your personal data with cloud storage providers.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        Analytic tools: We may share your personal data with analytics and
        search engine service providers.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        Administration and support tools: We may share your personal data with
        support software providers.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        Emailing: We may share your personal data with emailing service
        providers.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        CRM: We may share your personal data with CRM providers.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.45pt; margin-left:-.25pt;"
      >
        Payment processing: We may share your personal data with online payment
        processing providers.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.25pt; margin-left:-.25pt;"
      >
        Messaging and collaboration tools: We may share your personal data with
        messaging and collaboration software providers. Third Parties &ndash; We
        may integrate with other third party products and services such as
        Spotify to enable certain features of the Services. We do not share your
        personal information with them except as is necessary in order to allow
        them to perform the features requiring their integration.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.4pt; margin-left:-.25pt;"
      >
        Competition partners &ndash; When offering competitions or other prize
        draw, via the Services, we may process your personal information in
        order to manage your entry and contact you if you are a winner.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:106.05pt;margin-bottom: 0in;margin-left:-.25pt;line-height:140%;"
      >
        We may share your name and country of residence with anyone who asks for
        winner&rsquo;s details; and We may use your name and image in publicity
        materials as we see fit.
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:3.3pt; margin-left:-.25pt;"
      >
        We may share your personal information with our competition partner for
        the purpose of administering the competition (for example, arranging for
        the prize to be provided to the winner).
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:0in;margin-bottom:0in; margin-left:-.25pt;"
      >
        Event Partners &ndash; We are a ticketing agent, so we sell you tickets
        to events on behalf of the Event Partner for that event. We therefore
        need to share your personal details with them and other parties involved
        in the provision of the event (such as the venue) so they can provide
        you with information about the event such as last-minute changes, or for
        the purposes of ticket validation upon entry to a venue. We may also
        need to share your information with them in terms of sales control.
        Event partners can access customer information such as name, email
        address and a phone number. If you follow an artist using the App, we
        may also share your information with them. We are not responsible or
        liable for the actions of such third parties, and if you wish to
        complain about the use of your personal information by such third party
        then you should contact the relevant third party directly.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        Other reasons &ndash; We may also share your personal information with a
        purchaser or potential purchaser of our business and in some
        circumstances, we may have to disclose your personal information by law,
        either because a court or the police or other law enforcement agency has
        asked us for it, to enforce our legal rights, or to prevent fraud or
        ticket-touting for example.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        7.2. We may also provide our partners with anonymous information about
        how users, collectively, use the App and website so that they can
        understand how often people use their services, the App and the website.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        7.3. By providing us with personal information, you consent to the
        disclosure of your information outside the USand acknowledge that we are
        not required to ensure that overseas recipients handle that personal
        information in compliance with US federal data protection laws. You
        acknowledge that some overseas third parties may not be regulated by US
        data protection laws and if any third party engages in any act or
        practice that contravenes these laws, it would not be accountable under
        US legislation and you will not be able to seek redress under US data
        protection laws.
      </p>
      <p class="MsoNormal" style="margin-left:17.85pt;text-indent:-17.85pt;">
        <span style="line-height:112%;">
          8.
          <span style='font:7.0pt "Times New Roman";'>&nbsp;&nbsp;&nbsp;</span>
        </span>
        INFORMATION ABOUT OTHER INDIVIDUALS
      </p>
      <p class="MsoNormal" style="margin-left:36.5pt;">
        <span style="line-height:112%;">
          8.1.
          <span style='font:7.0pt "Times New Roman";'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        If you give us information (including personal information) on behalf of
        someone else, you confirm that the other person has given you permission
        to act on his/her behalf and has agreed that you can:
      </p>
      <p
        class="MsoNormal"
        style="margin-top:0in;margin-right:231.95pt;margin-bottom: 15.7pt;margin-left:-.25pt;line-height:140%;"
      >
        provide their personal information to us; give consent on their behalf
        to the processing of their personal information; receive on their behalf
        any data protection notices; and give consent to the transfer of the
        individual&rsquo;s personal information abroad.
      </p>
      <p class="MsoNormal" style="margin-left:36.5pt;">
        <span style="line-height:112%;">
          8.2.
          <span style='font:7.0pt "Times New Roman";'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Such consent should be proved by providing Ticketini with the written
        authorisation from the individual or the document which demonstrates
        power to represent him or her. In case these documents are not
        available, additional alternatives can be settled.
      </p>
      <p class="MsoNormal" style="margin-left:36.5pt;">
        <span style="line-height:112%;">
          8.3.
          <span style='font:7.0pt "Times New Roman";'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Where personal information has not been obtained from the individual,
        Ticketini will, upon request, provide the individual with the suitable
        information about how we handle their personal information, which is
        addressed by this Privacy Policy.
      </p>
      <p class="MsoNormal" style="margin-left:17.85pt;text-indent:-17.85pt;">
        <span style="line-height:112%;">
          9.
          <span style='font:7.0pt "Times New Roman";'>&nbsp;&nbsp;&nbsp;</span>
        </span>
        DATA RETENTION
      </p>
      <p class="MsoNormal" style="margin-left:36.5pt;">
        <span style="line-height:112%;">
          9.1.
          <span style='font:7.0pt "Times New Roman";'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        We may retain your personal information for as long as we require for
        the purposes for which it is collected or as is otherwise required by
        applicable law. If you terminate your account on the App and website,
        cease using the Services, or our agreement with you is terminated, we
        may continue to process your personal information in accordance with
        applicable law and this Privacy Policy.
      </p>
      <p class="MsoNormal" style="margin-left:36.5pt;">
        <span style="line-height:112%;">
          9.2.
          <span style='font:7.0pt "Times New Roman";'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        We may delete accounts or specific information before account
        deactivation where we believe it may no longer be relevant or where an
        account has been inactive for more than a year. Purchase history may be
        deleted after 8 years.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        For more details about our retention periods, please contact us at
        boxoffice@ticketini.io.
      </p>
      <p class="MsoNormal" style="margin-left:17.85pt;text-indent:-17.85pt;">
        <span style="line-height:112%;">
          10.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        YOUR RIGHTS
      </p>
      <p class="MsoNormal" style="margin-left:36.5pt;">
        <span style="line-height:112%;">
          10.1.
          <span style='font:7.0pt "Times New Roman";'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Choice and consent - Please read this Privacy Policy carefully. By
        providing personal information to us, you consent to us collecting,
        holding, using and disclosing your personal information in accordance
        with this Privacy Policy. You do not have to provide personal
        information to us, however, if you do not, it may affect your use of
        this Site or the products and/or services offered on or through it.
      </p>
      <p class="MsoNormal" style="margin-left:36.5pt;">
        <span style="line-height:112%;">
          10.2.
          <span style='font:7.0pt "Times New Roman";'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Anonymity - Where practicable we will give you the option of not
        identifying yourself or using a pseudonym in your dealings with us.
      </p>
      <p class="MsoNormal" style="margin-left:36.5pt;">
        <span style="line-height:112%;">
          10.3.
          <span style='font:7.0pt "Times New Roman";'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Restrict - If you have previously agreed to us using your personal
        information for direct marketing purposes, you may change your mind at
        any time by contacting us using the details below.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        10.3. Access - You may request details of the personal information that
        we hold about you. An administrative fee may be payable for the
        provision of such information.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        10.3. Correction - If you believe that any information we hold about you
        is inaccurate, out of date, incomplete, irrelevant or misleading, please
        contact us using the details below. We will take reasonable steps to
        correct any information found to be inaccurate, incomplete, misleading
        or out of date.
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        If you would like to exercise these rights, please write to us or email
        us at boxoffice@ticketini.io.
      </p>
      <p class="MsoNormal" style="margin-left:17.85pt;text-indent:-17.85pt;">
        <span style="line-height:112%;">
          11.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>
        </span>
        CONTACT AND COMPLAINTS
      </p>
      <p class="MsoNormal" style="margin-left:-.25pt;">
        Should you have any complaints about how we handle your personal
        information, please contact us at boxoffice@ticketini.i.
      </p>
    <p class="MsoNormal" style="margin-top:20px; margin-left:17.85pt; text-indent:-17.85pt;">
      <strong>
        <span style="line-height:112%;">
          12.<span style='font:7.0pt "Times New Roman";'>&nbsp;</span>ACCOUNT AND PERSONAL DATA DELETION
        </span>
      </strong>
    </p>
    <p class="MsoNormal" style="margin-left:-.25pt;">
      To request us to delete your account or personal data submitted please send your request to boxoffice@ticketini.io
    </p>
    </div>`,
      }}
    />
  );
};

export default PrivacyPolicy;
