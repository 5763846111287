import React, { useState } from "react";
import { AiFillApple } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { Navigate, useLocation } from "react-router-dom";

import {
  loginHandler,
  signUpHandler,
  googleSignInHandler,
  // appleSignInHandler, // Uncomment if Apple sign-in should be included
} from "../../utils/authHelper";
import BtnLoader from "../../components/shared/btnLoader";
import { useUserContext } from "../../context/User";
import ForgoPassword from "../../components/popups/forgotPassword";

const LoginPage = ({ title = "Get Started" }) => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [isRegistered, setIsRegistered] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showForgotPass, setShowForgotPass] = useState(false);

  const { loggedIn } = useUserContext();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const signInHandler = () => {
    if (isRegistered) loginHandler(email, pass, showLoader, setShowLoader);
    else signUpHandler(email, pass, confPass, showLoader, setShowLoader);
  };

  if (loggedIn) {
    return <Navigate to={from} replace />;
  }

  return showForgotPass ? (
    <div className="min-h-[65vh]">
      <ForgoPassword
        open={showForgotPass}
        onClose={() => setShowForgotPass(false)}
      />
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center min-h-[65vh] bg-white">
      <div className="w-full max-w-md px-8 py-6 mx-auto bg-white shadow-md rounded-xl">
        <h2 className="mb-4 text-lg font-semibold text-center">{title}</h2>
        <div className="mb-4">
          <input
            type="email" // Changed from text to email for semantic correctness
            className="w-full p-2 text-center border-b-2 outline-none"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <input
            type="password" // Changed from text to password for security
            className="w-full p-2 text-center border-b-2 outline-none"
            placeholder="Password"
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
        </div>
        {!isRegistered && (
          <div className="mb-4">
            <input
              type="password" // Ensure this is also password type
              className="w-full p-2 text-center border-b-2 outline-none"
              placeholder="Re-Enter Password"
              value={confPass}
              onChange={(e) => setConfPass(e.target.value)}
            />
          </div>
        )}
        <button
          className="flex justify-center w-full py-2 mb-4 text-white bg-blue-500 rounded hover:bg-blue-600"
          onClick={signInHandler}
        >
          {showLoader ? (
            <BtnLoader stroke="white" />
          ) : isRegistered ? (
            "Sign In"
          ) : (
            "Sign Up"
          )}
        </button>
        <div className="text-center">
          {isRegistered
            ? "Don't have an account? "
            : "Already have an account? "}
          <span
            className="font-semibold cursor-pointer"
            onClick={() => setIsRegistered(!isRegistered)}
          >
            {isRegistered ? "Sign Up" : "Sign In"}
          </span>
        </div>
        {isRegistered && (
          <div className="text-center">
            <span
              className="font-medium text-[13px] cursor-pointer"
              onClick={() => setShowForgotPass(true)}
            >
              Forgot Password
            </span>
          </div>
        )}
        <div className="my-4 text-center">OR</div>
        <div
          className="flex items-center justify-center w-full py-2 mb-2 text-center border cursor-pointer border-gray-400 hover:bg-gray-200"
          onClick={() => googleSignInHandler(showLoader, setShowLoader)}
        >
          <FcGoogle className="mr-2" />
          Continue With Google
        </div>
        {/* Uncomment if Apple sign-in should be included
        <div
          className="flex items-center justify-center w-full py-2 mb-2 text-center border cursor-pointer border-gray-400 hover:bg-gray-200"
          onClick={() => appleSignInHandler(showLoader, setShowLoader)}
        >
          <AiFillApple className="mr-2" />
          Continue With Apple
        </div>
        */}
        <div className="mt-4 text-xs text-center">
          I agree to the <span className="underline">Terms & Conditions</span>{" "}
          &amp; <span className="underline">Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
