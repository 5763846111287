import React, { useEffect, useState } from "react";
import {
  getDocs,
  query,
  collection,
  where,
  orderBy,
  limit,
} from "firebase/firestore";

import HomeBanner from "./homeBanner";
import banner1 from "./homeBanner/homeBannerImage/banner1.jpg";
import banner2 from "./homeBanner/homeBannerImage/banner2.jpg";
import banner3 from "./homeBanner/homeBannerImage/banner3.JPG";
import { firestore } from "../../firebase";

import "./index.css";
import Slider from "../../components/shared/slider";
import Loader from "../../components/shared/loader";
import { firstSliderimageData } from "./images/firstSliderImg";
import Recommended from "./recommended";
import moment from "moment";

const comingSoonImage = require("./images/comingSoon.png");

const Home = () => {
  const [eventData, setEventData] = useState([]);
  const [concertEvents, setConcertEvents] = useState([]);
  const [playEvents, setPlayEvents] = useState([]);
  const [musicalEvents, setMusicalEvents] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const limitNum = 20;

  const fetchEvents = async () => {
    try {
      setShowLoader(true);
      var snap = await getDocs(
        query(
          collection(firestore, "Events"),
          where("status", "==", "published"),
          where("endDate", ">", Date.now()),
          orderBy("endDate", "desc"),
          limit(limitNum)
        )
      );
      if (snap.docs.length) {
        const newData = snap.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            date: new Date(data.startDate),
            id: doc.id,
          };
        });
        setEventData(newData);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  const fetchSpecificEvents = async (type, setter) => {
    try {
      setShowLoader(true);
      var snap = await getDocs(
        query(
          collection(firestore, "Events"),
          where("status", "==", "published"),
          where("eventType", "==", type),
          where("endDate", ">", Date.now()),
          orderBy("endDate", "desc"),
          limit(limitNum)
        )
      );
      if (snap.docs.length) {
        const newData = snap.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            date: new Date(data.startDate),
            id: doc.id,
          };
        });
        setter(newData);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    fetchEvents();
    fetchSpecificEvents("Concert", setConcertEvents);
    fetchSpecificEvents("Play", setPlayEvents);
    fetchSpecificEvents("Musical", setMusicalEvents);
  }, []);

  return (
    <>
      {!showLoader ? (
        eventData.length ? (
          <div className="bg-[#f5f5f5] text-white pb-16 ">
            <div className="w-[100%] h-[200px] md:h-[350px] ">
              <Slider
                data={firstSliderimageData}
                isBanner
                disableLink
                autoplay
              />
            </div>

            <div className="sm:mt-[11rem]  mt-[11rem] mx-auto w-[95%] md:mt-14">
              <Slider
                data={eventData}
                sliderTitle="Latest Events"
                extraClass="pb-2"
                tag=""
              />
            </div>

            {!!concertEvents.length && (
              <>
                <HomeBanner
                  head={`Best Concerts ${moment().year()}`}
                  img={banner1}
                  overlayClass="homeBannerBg1"
                  img2={banner2}
                />
                <div className=" md:h-[450px] h-[250px] sm:mt-[4] mt-4 mx-auto w-[95%] md:mt-8">
                  <Slider
                    data={concertEvents}
                    sliderTitle="Concerts"
                    extraClass="pb-2"
                    tag="Concert"
                  />
                </div>
              </>
            )}

            {!!playEvents.length && (
              <>
                <HomeBanner
                  head="Endless Entertainment!"
                  img={banner2}
                  overlayClass="homeBannerBg2"
                  img2={banner3}
                />
                <div className="sm:mt-[4] mt-4 mx-auto w-[95%] md:mt-8 md:h-[450px] h-[250px]">
                  <Slider
                    data={playEvents}
                    sliderTitle="Plays"
                    extraClass="pb-2"
                    tag="Play"
                  />
                </div>
              </>
            )}

            {!!eventData?.length && <Recommended data={eventData} />}

            {!!musicalEvents.length && (
              <>
                <HomeBanner
                  head={
                    <span className="">
                      Best M<span className="text-[red]">u</span>s
                      <span className="text-[red]">i</span>c
                      <span> shows {moment().year()}</span>
                    </span>
                  }
                  img={banner3}
                  overlayClass="homeBannerBg3"
                  img2={banner2}
                />
                <div className="sm:mt-[4] mt-4 mx-auto w-[95%] md:mt-8  md:h-[450px] h-[250px]">
                  <Slider
                    data={musicalEvents}
                    sliderTitle="Musical"
                    extraClass="pb-2"
                    tag="Musical"
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <img
            src={comingSoonImage}
            className="w-[100vw] h-[55vh] sm:h-[90vh] border-y border-y-white"
            alt=""
          />
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Home;
