import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/shared/loader";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const { clientSecret } = useParams();
  const navigate = useNavigate();

  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const transactionKey = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!transactionKey) {
      return;
    }

    setShowLoader(true);
    stripe
      .retrievePaymentIntent(transactionKey)
      .then(({ paymentIntent }) => {
        if (paymentIntent.status === "succeeded") {
          navigate(`/booking-history/${paymentIntent.id}`);
        } else {
          setStatus("error");
          setMessage("Unexpected error occurred");
        }
      })
      .finally(() => setShowLoader(false));
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: `http://localhost:3000/checkout/${clientSecret}`,
        return_url: `${process.env.REACT_APP_WEBSITE_URL}/checkout/${clientSecret}`,
      },
    });

    if (
      error &&
      (error.type === "card_error" || error.type === "validation_error")
    ) {
      setMessage(error.message);
    }

    setIsLoading(false);
  };

  return showLoader ? (
    <Loader />
  ) : (
    <div className="flex justify-center items-center h-screen">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded border border-gray-200 shadow-lg"
      >
        {status != "success" && (
          <>
            <p className="text-black mb-4">Complete your payment here!</p>
            <PaymentElement />
            <div className="flex justify-center">
              <button
                className="rounded-xl text-white p-2 px-4 mt-6 mb-2"
                disabled={isLoading || !stripe || !elements}
                style={{ backgroundColor: "#333545" }}
              >
                {isLoading ? "Wait..." : "Pay now"}
              </button>
            </div>
          </>
        )}
        {!!message && (
          <div className="flex justify-center">
            <p
              className={`${
                status == "success" ? "text-green-500" : "text-red-500"
              } text-sm`}
            >
              {message}
            </p>
          </div>
        )}
      </form>
    </div>
  );
}
