import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./index.css";
import AddSectionForm from "./addSectionForm";
import SeatMapContainer from "./seatMapContainer";
import BtnLoader from "../../components/shared/btnLoader";
import { useUserContext } from "../../context/User";
import { adminAccounts } from "../../constants";

const CreateSeatMap = () => {
  const [sections, setSections] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deletePopupMsg, setDeletePopupMsg] = useState("");
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const { token, ticketSetupId } = useParams();
  const { user } = useUserContext();
  const isAdmin = adminAccounts.includes(user?.userId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token)
      return setErrorMsg(
        "Authentication token required. Please regenerate the link from mobile app. "
      );
    if (!ticketSetupId)
      return setErrorMsg(
        "Setup id required. Please regenerate the link from mobile app."
      );
    setLoadingData(true);
    fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/getSeatMap?ticketSetupId=${ticketSetupId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.error) {
          console.log("err", data);
          setErrorMsg(data?.message || "Error");
        }
        if (data?.ticketSetupData?.details?.length) {
          console.log("d", data);
          setErrorMsg("");
          setSections(data.ticketSetupData.details);
        }
      })
      .catch((error) => {
        console.log("err", error);
        setErrorMsg(error?.message || "Error");
      })
      .finally(() => setLoadingData(false));
  }, []);

  const onSave = async () => {
    if (showLoader) return;
    setShowLoader(true);
    fetch(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/updateSeatMap`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ticketSetupId,
          ticketSetupPayload: sections.map((sec, secInd) => ({
            ...sec,
            price: Number(sec.price),
            rows: sec.rows.map((r, rInd) => ({
              ...r,
              seats: r.seats.map((seat, seatInd) => ({
                ...seat,
                isAvailable: true,
              })),
            })),
          })),
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => console.log("d", data))
      .catch((err) => console.log("err", err))
      .finally(() => {
        setShowLoader(false);
        if (isAdmin) {
          alert("Saved");
          navigate("/seatingRequests");
        } else if (isMobile)
          window.location.href = `${process.env.REACT_APP_TICKETINI_DEEPLINK_URL}/seatMapSaved`;
        else alert("Saved");
      });
  };

  const handleAddSection = (section) => {
    setSections([
      ...sections,
      {
        sectionId: sections.length,
        sectionName: section.sectionName,
        price: section.price,
        rows: generateRows(section.rows, section.columns, section.startChar),
        firstChar: section.startChar,
      },
    ]);
  };

  const getLabel = (index, startChar) => {
    const startCharCode = startChar.toUpperCase().charCodeAt(0); // Convert startChar to its ASCII code
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const offsetIndex = index + (startCharCode - 65); // Adjust index based on startCharCode
    const firstLetter = letters[Math.floor(offsetIndex / 26) - 1] || ""; // Prefix letter for indices above 25
    const secondLetter = letters[offsetIndex % 26];
    return firstLetter + secondLetter;
  };

  const generateRows = (numRows, numColumns, startChar) => {
    return Array.from({ length: numRows }, (_, rowIndex) => ({
      rowId: rowIndex,
      label: getLabel(rowIndex, startChar), // Get label for the row
      seats: Array.from({ length: numColumns }, (_, seatIndex) => ({
        number: seatIndex + 1,
        notEmptySpace: true,
      })),
    }));
  };

  const moveSection = (direction, index) => {
    if (direction === "down" && index > 0) {
      setSections([
        ...sections.slice(0, index - 1),
        sections[index],
        sections[index - 1],
        ...sections.slice(index + 1),
      ]);
    } else if (direction === "up" && index < sections.length - 1) {
      setSections([
        ...sections.slice(0, index),
        sections[index + 1],
        sections[index],
        ...sections.slice(index + 2),
      ]);
    }
  };

  const handleMoveUp = (index) => {
    moveSection("up", index);
  };

  const handleMoveDown = (index) => {
    moveSection("down", index);
  };

  const promptDeleteSection = (index) => {
    setSectionToDelete(index);
    setShowDeletePopup(true);
  };

  const confirmDeleteSection = () => {
    if (sectionToDelete !== null) {
      var tempArr = [...sections];
      tempArr.splice(sectionToDelete, 1);
      setSections(tempArr);
    }
    setShowDeletePopup(false);
    setSectionToDelete(null);
  };

  const cancelDelete = () => {
    setShowDeletePopup(false);
    setSectionToDelete(null);
  };

  const editSectionHandler = (sectionIndex, info) => {
    var tempArr = [...sections];
    tempArr[sectionIndex].sectionName = info.sectionName;
    tempArr[sectionIndex].price = info.price;
    tempArr[sectionIndex].rows = tempArr[sectionIndex].rows.map(
      (row, rInd) => ({
        ...row,
        label: getLabel(rInd, info.startChar),
      })
    );
    tempArr[sectionIndex].firstChar = info.startChar;
    setSections(tempArr);
  };

  const toggleRowAvailability = (sectionIndex, rowIndex) => {
    if (sections[sectionIndex].rows.length == 1) {
      setDeletePopupMsg(
        `Deleting last row would delete the section "${sections[sectionIndex].sectionName}". Do you wish to proceed?`
      );
      promptDeleteSection(sectionIndex);
    } else {
      var totalSeats = sections[sectionIndex].rows[rowIndex].seats.length;
      var totalAvailableSeats = 0;
      sections[sectionIndex].rows.forEach((row, rInd) => {
        if (rInd == rowIndex) {
          row.seats.forEach((seat) => {
            if (seat.notEmptySpace) totalAvailableSeats++;
          });
        }
      });
      const updatedSections = sections.map((sec, secInd) => ({
        ...sec,
        rows: sec.rows.map((r, rInd) => ({
          ...r,
          seats: r.seats.map((seat, seatInd) => ({
            ...seat,
            notEmptySpace:
              secInd == sectionIndex && rInd == rowIndex
                ? totalAvailableSeats < totalSeats * 0.5
                : seat.notEmptySpace,
          })),
        })),
      }));
      setSections(filterEmptyRowColumn(updatedSections));
    }
  };

  const toggleColumnAvailability = (sectionIndex, columnIndex) => {
    if (sections[sectionIndex].rows[0].seats.length == 1) {
      setDeletePopupMsg(
        `Deleting last column would delete the section "${sections[sectionIndex].sectionName}". Do you wish to proceed?`
      );
      promptDeleteSection(sectionIndex);
    } else {
      var totalSeats = 0;
      var totalAvailableSeats = 0;
      sections[sectionIndex].rows.forEach((row) => {
        row.seats.forEach((seat, seatInd) => {
          if (seatInd == columnIndex) totalSeats++;
          if (seatInd == columnIndex && seat.notEmptySpace)
            totalAvailableSeats++;
        });
      });
      const updatedSections = sections.map((sec, secInd) => ({
        ...sec,
        rows: sec.rows.map((r, rInd) => ({
          ...r,
          seats: r.seats.map((seat, seatInd) => ({
            ...seat,
            notEmptySpace:
              secInd == sectionIndex && seatInd == columnIndex
                ? totalAvailableSeats < totalSeats * 0.5
                : seat.notEmptySpace,
          })),
        })),
      }));
      setSections(filterEmptyRowColumn(updatedSections));
    }
  };

  const toggleSeatAvailability = (sectionIndex, rowIndex, columnIndex) => {
    if (
      sections[sectionIndex].rows[0].seats.length == 1 &&
      sections[sectionIndex].rows.length == 1
    ) {
      setDeletePopupMsg(
        `Deleting last seat would delete the section "${sections[sectionIndex].sectionName}". Do you wish to proceed?`
      );
      promptDeleteSection(sectionIndex);
    } else {
      const updatedSections = sections.map((sec, secInd) => ({
        ...sec,
        rows: sec.rows.map((r, rInd) => ({
          ...r,
          seats: r.seats.map((seat, seatInd) => ({
            ...seat,
            notEmptySpace:
              secInd == sectionIndex &&
              rInd == rowIndex &&
              seatInd == columnIndex
                ? !seat.notEmptySpace
                : seat.notEmptySpace,
          })),
        })),
      }));
      setSections(filterEmptyRowColumn(updatedSections));
    }
  };

  const addNewColumn = (sectionIndex) => {
    const updatedSections = sections.map((sec, secInd) => ({
      ...sec,
      rows: sec.rows.map((r) => ({
        ...r,
        seats:
          secInd == sectionIndex
            ? [
                ...r.seats,
                {
                  number: r.seats.length + 1,
                  notEmptySpace: true,
                },
              ]
            : [...r.seats],
      })),
    }));
    setSections(filterEmptyRowColumn(updatedSections));
  };

  const onAddRow = (sectionIndex) => {
    const updatedSections = sections.map((sec, secInd) => ({
      ...sec,
      rows:
        secInd == sectionIndex
          ? [
              ...sec.rows,
              {
                label: getLabel(
                  1,
                  sections[sectionIndex].rows[
                    sections[sectionIndex].rows.length - 1
                  ].label
                ),
                seats: Array.from(
                  { length: sec.rows[0].seats.length },
                  (_, seatIndex) => ({
                    number: seatIndex + 1,
                    notEmptySpace: true,
                  })
                ),
              },
            ]
          : [...sec.rows],
    }));
    setSections(filterEmptyRowColumn(updatedSections));
  };

  const filterEmptyRowColumn = (arr) => {
    var tempArr = arr
      .map((section) => ({
        ...section,
        rows: section.rows.filter((row, rowInd) => {
          if (rowInd == 0 || rowInd == section.rows.length - 1) {
            return !!row.seats.find((seat) => seat.notEmptySpace);
          } else return true;
        }),
      }))
      .map((section) => ({
        ...section,
        rows: section.rows.map((row) => ({
          ...row,
          seats: row.seats.filter((_, columnIndex) => {
            if (
              row.seats.length > 1 &&
              (columnIndex == 0 || columnIndex == row.seats.length - 1)
            ) {
              var isEmptyColumn = true;
              section.rows.forEach((r) => {
                if (r.seats[columnIndex].notEmptySpace) isEmptyColumn = false;
              });
              return !isEmptyColumn;
            } else return true;
          }),
        })),
      }));

    if (JSON.stringify(arr) == JSON.stringify(tempArr))
      return reArrangeLabels(tempArr);
    else return filterEmptyRowColumn(tempArr);
  };

  const reArrangeLabels = (arr) => {
    return arr.map((section) => {
      let filledRows = 0;
      const updatedRows = section.rows.map((row, rowInd) => {
        var filledSeats = 0;
        const updatedSeats = row.seats.map((seat) => {
          if (!seat.notEmptySpace) {
            return { ...seat, number: "" };
          } else {
            filledSeats++;
            return { ...seat, number: filledSeats };
          }
        });

        if (!row.seats.find((seat) => seat.notEmptySpace)) {
          return { ...row, label: "" };
        } else {
          const newLabel = getLabel(filledRows, section.firstChar);
          filledRows++;
          return { ...row, label: newLabel, seats: updatedSeats };
        }
      });

      return { ...section, rows: updatedRows };
    });
  };

  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const isMobile = width <= 768;

  return (
    <div className="p-6 min-h-[60vh]">
      {loadingData ? (
        <div className="flex items-center justify-center w-full">
          <BtnLoader stroke="black" size={25} />
        </div>
      ) : errorMsg ? (
        <div className="text-[16px] text-center">{errorMsg}</div>
      ) : (
        <div>
          {showPopup && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
              <AddSectionForm
                onAddSection={handleAddSection}
                onClose={() => {
                  setEditingIndex(-1);
                  setShowPopup(false);
                }}
                editingSection={
                  editingIndex >= 0 ? sections[editingIndex] : null
                }
                editingIndex={editingIndex}
                editSectionHandler={editSectionHandler}
              />
            </div>
          )}

          <div className="overflow-x-auto no-scrollbar mt-6">
            <div className="min-w-max space-y-6 p-4 border rounded shadow">
              {sections.map((section, index) => (
                <SeatMapContainer
                  key={index}
                  section={section}
                  onMoveDown={handleMoveUp}
                  onMoveUp={handleMoveDown}
                  toggleColumnAvailability={(colInd) =>
                    toggleColumnAvailability(index, colInd)
                  }
                  toggleRowAvailability={(rowInd) =>
                    toggleRowAvailability(index, rowInd)
                  }
                  toggleSeatAvailability={(rowInd, colInd) =>
                    toggleSeatAvailability(index, rowInd, colInd)
                  }
                  addNewColumn={addNewColumn}
                  onAddRow={onAddRow}
                  index={index}
                  length={sections.length}
                  onDeleteSection={() => {
                    setDeletePopupMsg(
                      `Are you sure you want to delete the section "${sections[index].sectionName}"?`
                    );
                    promptDeleteSection(index);
                  }}
                  onEdit={() => {
                    setEditingIndex(index);
                    setShowPopup(true);
                  }}
                />
              ))}
              <div className={isMobile ? "ml-20" : "flex justify-center"}>
                <button
                  className="bg-blue-500 text-white p-2 rounded hover:bg-blue-700 w-32"
                  onClick={() => setShowPopup(true)}
                >
                  Add Section
                </button>
              </div>
              {!!sections.length && (
                <>
                  <div
                    className={isMobile ? "ml-20" : "flex justify-center"}
                    style={{ marginTop: 10 }}
                  >
                    <button
                      className="bg-green-500 text-white p-2 rounded hover:bg-green-700 w-32 flex  items-center justify-center"
                      onClick={onSave}
                    >
                      {showLoader ? (
                        <BtnLoader stroke="white" size={20} />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                  <div className="text-[14px]">
                    *Tap on any seat to add/delete
                  </div>
                  <div className="text-[14px]" style={{ marginTop: 0 }}>
                    *Tap on + icons to add rows/columns
                  </div>
                  <div className="text-[14px]" style={{ marginTop: 0 }}>
                    *Tap on vertical line of boxes on the left of seats to
                    add/delete rows
                  </div>
                  <div className="text-[14px]" style={{ marginTop: 0 }}>
                    *Tap on horizontal line boxes on top of seats to add/delete
                    columns
                  </div>
                </>
              )}
            </div>
          </div>

          {showDeletePopup && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-4 rounded shadow-lg">
                <h2 className="text-lg">Confirm Deletion</h2>
                <p>{deletePopupMsg}</p>
                <div className="flex justify-end space-x-4 mt-4">
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700"
                    onClick={confirmDeleteSection}
                  >
                    Delete
                  </button>
                  <button
                    className="bg-gray-300 text-gray-800 px-4 py-2 rounded hover:bg-gray-400"
                    onClick={cancelDelete}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default CreateSeatMap;
