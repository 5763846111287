import React, { useEffect, useState } from "react";
import BookingCard from "./bookingCard";
import Loader from "../../components/shared/loader";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";

const BookHistory = () => {
  const [data, setData] = useState([]);
  const [lastDoc, setLastDoc] = useState("");
  const [page, setPage] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [dataToShow, setDataToShow] = useState([]);
  const limitNum = 6;

  const getTicketData = async () => {
    setShowLoader(true);
    try {
      const res = await httpsCallable(
        functions,
        "getTicketsByUser"
      )({ limit: limitNum });
      if (res.data?.status == 200) {
        const arr = res.data.tickets || [];
        setData(arr);
        if (arr.length == limitNum) setLastDoc(arr[arr.length - 1]);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setShowLoader(false);
    }
  };

  const getMoreTicketData = async () => {
    if (data.length > page + limitNum) setPage(page + limitNum);
    else {
      try {
        setShowLoader(true);
        const res = await httpsCallable(
          functions,
          "getTicketsByUser"
        )({ limit: limitNum, startAfter: lastDoc?.ticketData?.id });
        if (res.data?.status == 200) {
          const arr = res.data.tickets || [];
          setData([...data, ...arr]);
          setPage(page + limitNum);
          if (arr.length == limitNum) setLastDoc(arr[arr.length - 1]);
        }
      } catch (err) {
        console.log("err", err);
      } finally {
        setShowLoader(false);
      }
    }
  };

  useEffect(() => {
    getTicketData();
  }, []);

  useEffect(() => {
    if (!data.length) return;
    var arr = data.slice(page, page + limitNum);
    setDataToShow(arr);
  }, [data, page, lastDoc]);

  return showLoader ? (
    <Loader />
  ) : (
    <div className={data.length ? "min-h-[80vh]" : "min-h-[60vh]"}>
      <div className="text-[20px] font-bold text-center mt-2  ">
        Booking History
      </div>

      {dataToShow.length ? (
        <>
          <div className="lg:w-[80%] md:w-[100%] justify-between  sm:w-[80%] w-[80%]  mx-auto  py-2 md:flex md:flex-wrap flex-nowrap  px-2">
            {dataToShow.map((item, index) => {
              return <BookingCard key={`booking${index}`} data={item} />;
            })}
          </div>
          <div className="flex justify-center mb-4">
            {!!page && (
              <button
                className="bg-gray-600 hover:bg-gray-800 py-2 px-4 rounded mx-2 text-white"
                onClick={() => setPage(page - limitNum)}
              >
                Prev
              </button>
            )}
            {(!!lastDoc || data.length > page + limitNum) && (
              <button
                className="bg-gray-600 hover:bg-gray-800 py-2 px-4 rounded mx-2 text-white"
                onClick={getMoreTicketData}
              >
                Next
              </button>
            )}
          </div>
        </>
      ) : (
        <p className="text-center  text-gray-500 uppercase mt-4 font-[600] font-dancing">
          No ticket history to show
        </p>
      )}
    </div>
  );
};

export default BookHistory;
