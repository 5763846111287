import React from "react";

const AlertPopup = ({
  title,
  buttons = [{ text: "OK", onPress: () => {}, className: "" }],
  hidePopup = () => {},
}) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white rounded-lg shadow-lg p-5 md:p-10">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">{title}</h2>
        <div className="flex justify-center gap-4">
          {buttons.map((btn) => (
            <button
              className={`py-2 px-4 rounded ${
                btn?.className || "bg-gray-500 text-white"
              }`}
              onClick={() => {
                btn?.onPress && btn.onPress();
                hidePopup();
              }}
            >
              {btn.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlertPopup;
