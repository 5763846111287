import React from 'react';

const UniVersalContainer = ({fluid , children , extraClass}) => {
    return (
        <div className={` ${fluid === true ? "w-full" : "container"} ${extraClass ?? ""}  mx-auto  `} >
            {children}
        </div>
    );
};

export default UniVersalContainer;