import React, { useEffect } from "react";
import UniVersalContainer from "../container";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const query = new URLSearchParams(search);
  const toHideNavFooter = query.get("hideNavFooter");

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return pathname === "/chatSupport" || toHideNavFooter ? null : (
    <>
      <UniVersalContainer
        fluid
        extraClass="bg-[#21304c]  py-6  flex items-center flex-col "
      >
        <div className="w-[85%] flex py-5 justify-between items-center">
          <div className="h-[1px] pe-2 bg-[#5D5D5F] w-[100%] sm:w-[47%] ms-3  lg:w-[41%]"></div>
          <div
            className="sm:text-[33px] lg:text-[36px] text-[24px] lg:px-0 
            xl:pe-2 px-4  text-center w-[50%]  lg:w-[180px] font-mono font-bold
            text-white"
          >
            TICKE<span className="text-[#C4242B]">TI</span>NI
          </div>
          <div className="bg-[#5D5D5F] h-[1px] w-[100%] sm:w-[47%] me-3 lg:w-[41%]"></div>
        </div>
        <UniVersalContainer
          extraClass="w-[85%] flex items-center 
          text-[#666666] text-[11px] justify-center flex-col"
        >
          <div className="text-center mb-4">
            The content and images used on this site are copyright protected and
            copyrights vests with the respective owners. The usage of the
            content and images on this website is intended to promote the works
            and no endorsement of the artist shall be implied. Unauthorized use
            is prohibited and punishable by law.
          </div>
          {/* Buttons Container */}
          <div className="flex justify-center gap-4 mb-2">
            <button
              className="text-white hover:text-gray-300"
              onClick={() => navigate("/terms-of-use")}
            >
              Terms of Use
            </button>
            <button
              className="text-white hover:text-gray-300"
              onClick={() => navigate("/terms-and-conditions")}
            >
              Terms and Conditions
            </button>
            <button
              className="text-white hover:text-gray-300"
              onClick={() => navigate("/purchase-terms")}
            >
              Purchase Terms
            </button>
          </div>
        </UniVersalContainer>
      </UniVersalContainer>
      <div className="text-center text-white text-[10px] sm:text-[12px] bg-[#21304c] w-full pb-7 pt-2">
        Copyright 2024 © Ticketini. All Rights Reserved.
      </div>
    </>
  );
};

export default Footer;
